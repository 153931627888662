import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getOutputCategoryFilter,
  getStreamAllTimeSummary,
  getAllTimeStats,
} from "../../selectors/data";

import { setOuputCategoryFilter } from "../../actions";
import PieView from "./components/pieView";
import { formatWeight } from "../../selectors/helpers";

function OutputByStreamAllTime() {
  const dispatch = useDispatch();

  const outputsByStream = useSelector((state) =>
    getStreamAllTimeSummary(state)
  );

  const stats = useSelector((state) => getAllTimeStats(state));

  const outputCategoryFilter = useSelector((state) =>
    getOutputCategoryFilter(state)
  );

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!outputCategoryFilter) {
      setTotal(stats.total);
    } else {
      setTotal(stats.parent_streams[outputCategoryFilter.value.id]);
    }
  }, [stats, outputCategoryFilter]);
  return (
    <PieView
      data={outputsByStream}
      total={total}
      units="tonne"
      toolTipRowFunction={(data) => {
        // console.log(data);
        return [
          {
            label: "total",
            value: formatWeight(data.total),
          },
          {
            label: "percent",
            value: formatWeight(data.percent, "%", (v) => v * 100),
          },
          {
            label: "daily avg",
            value: formatWeight(data.daily_average),
            // Numeral(data.daily_average).format("0,0.00") + " kg",
          },
        ];
      }}
      onClick={(node) => {
        dispatch(
          setOuputCategoryFilter(
            outputCategoryFilter
              ? null
              : {
                  type: node.data.type ? node.data.type : "unknown",
                  value: node.data,
                }
          )
        );
      }}
    />
  );
}

export default OutputByStreamAllTime;
