import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import dataReducer from "./reducers/data";
import interactionReducer from "./reducers/interaction";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./middleware/rootSaga";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

dayjs.tz.setDefault("Pacific/Norfolk");

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const initialState = {
  data: {
    dateRange: {
      start: dayjs.tz().subtract(1, "month").startOf("month").valueOf(),
      end: dayjs.tz().subtract(1, "month").endOf("month").valueOf(),
    },
  },
};

const store = configureStore({
  reducer: { data: dataReducer, interaction: interactionReducer },
  middleware: middleware,
  preloadedState: initialState,
});

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
