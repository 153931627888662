import Numeral from "numeral";
import dayjs from "dayjs";

export const sortByTime = (
  collection,
  prop = "completed",
  ascending = true
) => {
  collection.sort((a, b) => b[prop] - a[prop]);

  return collection;
};
export const collectionConvertTimeToDate = (
  collection,
  prop = "completed",
  output_prop = "",
  format = "DD/MM/YY"
) => {
  // first sort into order
  // collection.sort((a, b) => b[prop] - a[prop]);
  // sortByTime(collection);

  // var tzoffset = new Date().getTimezoneOffset() * 60000;

  let results = [];
  collection.forEach((item) => {
    let result = { ...item };

    let d = convertDate(item[prop], format);
    // result[prop] = item[prop] ? d.toISOString().split("T")[0] : "-";
    result[prop + "_formatted"] = item[prop] ? d : "-";

    // if (includeTime) {
    //   result[prop + "_time"] = d.toLocaleTimeString();
    // }

    results.push(result);
  });

  return results;
  // return collection;
};

const hex2rgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // return {r, g, b}
  return { r, g, b };
};

// On Island Re-Use
// On island Recycle
// Export Recycle
// Export landfill
// On island incineration
export const getProcessColors = (key) => {
  // console.log("getProcessColors", key);
  return getUniqueColor(key);
  // switch (key) {
  //   case "exportlandfill":
  //     return "#C16E70";

  //   case "exportrecycle":
  //     return "#FFD489";

  //   case "onislandre-use":
  //   case "onislandreuse":
  //     return "#A77BEE";

  //   case "onislandrecycle":
  //     return "#BDE4A8";

  //   case "onislandincineration":
  //     return "#999999";

  //   default:
  //     return "#999999";
  // }
};

export const getContamColors = (key) => {
  return getUniqueColor(key);
  // switch (key) {
  //   case "contam":
  //     return "#C16E70";

  //   case "clean":
  //     return "#BDE4A8";

  //   default:
  //     return "#999999";
  // }
};

export const getUniqueColor = (key) => {
  key = convertNoSpaceLower(key);
  // console.log("HELPER > getUniqueColor > KEY", key);
  switch (key) {
    case "pending":
      return "#EDAE3D";
    case "approved":
      return "#00A6FB";
    case "submitted":
      return "#00A853";
    case "storage":
      return "#E295D2";
    case "exportlandfill":
      return "#C16E70";
    case "exportrecycle":
      return "#FFD489";
    case "onislandreuse":
      return "#A77BEE";
    case "onislandrecycle":
      return "#BDE4A8";
    case "onislandincineration":
      return "#999999";
    case "contam":
      return "#C16E70";
    case "clean":
      return "#BDE4A8";
    case "unknown":
      return "#DDDDDD";

    default:
      return generateUniqueColor(key);
  }
};

const generateUniqueColor = (str) => {
  // Create a hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate a hue value based on the hash
  const hue = ((hash % 360) + 360) % 360;

  // Use a fixed saturation and lightness to ensure colors are not too dark
  const saturation = 70; // 70% saturation
  const lightness = 70; // 70% lightness

  // Convert to HSL color
  const hslColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  // Convert HSL to hex
  const hslToHex = (h, s, l) => {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  };

  return hslToHex(hue, saturation, lightness);
};

export const getDateRangeString = (dateRange) => {
  const start = dayjs(dateRange.start).tz().format("MMM YYYY");
  const end = dayjs(dateRange.end).tz().format("MMM YYYY");

  if (start === end) return start;
  else return start + " ~ " + end;
};

export const getAverageColor = (source, colorProp = "color") => {
  let r = 0;
  let g = 0;
  let b = 0;

  source.forEach((element) => {
    let hex = element[colorProp];
    if (hex) {
      hex = hex.trim();

      let rgb = hex2rgb(hex);

      // console.log("RGB", rgb);
      r += rgb.r;
      g += rgb.g;
      b += rgb.b;
    }
  });

  r = Math.round(r / source.length);
  g = Math.round(g / source.length);
  b = Math.round(b / source.length);

  return "#" + r.toString(16) + g.toString(16) + b.toString(16);
};

export const formatWeight = (
  value,
  unit = "t",
  modifier = (v) => v,

  format = "0,0.00"
) => {
  return value && value > 0
    ? Numeral(modifier(value)).format(format) + (unit ? " " + unit : "")
    : "0" + (unit ? " " + unit : "");
};

export const kgToTon = (value) => {
  return value / 1000;
  // return Math.round(value / 10) / 100;
};

export const toDate = (value) => {
  return convertDate(value, "DD/MM/YY");
};

export const getDayOfWeek = (value) => {
  return String(dayjs(value).format("dddd")).toLowerCase();
};

export const toTime = (value) => {
  return convertDate(value, "hh:mm a");
};
export const convertDate = (value, format = "DD/MM/YY") => {
  return dayjs.tz(value).format(format);
};

export const getDiffInDays = (d1, d2) => {
  console.log("HELPER > getDiffInDays", d1, d2);
  return dayjs.tz(d2).diff(d1, "day", true);
};

export const jsonToCsv = (json) => {
  console.log("JSONTOCSV", json);
  if (!json || json.length === 0) return [];

  const csvRows = [];
  const headers = Object.keys(json[0]);
  csvRows.push(headers.join(",") + ",time");
  // csvRows.push();

  for (const row of json) {
    const values = headers.map((header) => {
      let v = row[header];
      if (typeof v === "object" && v !== null) {
        console.log(" V = object", v);
        v = v.name;
      }
      const escaped = ("" + v).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });

    values.push(dayjs(row["completed"]).format("DD/MM/YY hh:mm:ss"));
    csvRows.push(values.join(","));
  }
  return csvRows.join("\n");
};

export const getDayOfWeekCount = (startDate, endDate, dayOfWeek) => {
  // console.log("HELPER > getDayOfWeekCount", startDate, endDate, dayOfWeek);

  let count = 0;
  let current = dayjs(startDate).startOf("day");
  let end = dayjs(endDate).endOf("day");

  while (current.valueOf() <= end.valueOf()) {
    // console.log("HELPER > getDayOfWeekCount", startDate, endDate, dayOfWeek);

    if (
      String(current.format("dddd")).toLowerCase() ===
      String(dayOfWeek).toLowerCase()
    ) {
      count++;
    }
    current = current.add(1, "day");
  }

  return count;
};

export const determineTimeGrouping = (days) => {
  if (days > 90)
    // 6 months or more
    return "month";
  else if (days > 28)
    // 3 months or more
    return "week";
  else if (days > 1)
    //  2 weeks or more
    return "day";
  else return "hour";
};

export const convertNoSpaceLower = (string) => {
  return string ? string.replace(/\W/g, "").toLowerCase() : "undefined";
};

export const getTimeGroupFormate = (grouping) => {
  if (grouping === "month") return "MMM YY";
  else if (grouping === "week") return "DD MMM";
  else if (grouping === "day") return "ddd DD MMM";
  else if (grouping === "hour") return "HH";
};

export const generateTimeGroupingSeries = (timeGrouping, start, end) => {
  let series = [];
  let workingTime = start; //dayjs.tz(start).startOf(timeGrouping).valueOf();

  console.log("TIME SERIES", timeGrouping, start, end);
  while (workingTime < dayjs.tz(end).endOf(timeGrouping).valueOf()) {
    let value = timeToSeriesItem(workingTime, timeGrouping);
    if (!series.some((item) => item.id === value.id)) {
      series.push(value);
    }

    workingTime = dayjs.tz(workingTime).add(1, timeGrouping).valueOf();
  }
  return series;
};

export const timeToSeriesItem = (workingTime, timeGrouping) => {
  let start = dayjs.tz(workingTime).startOf(timeGrouping);
  let end = start.add(1, timeGrouping);

  return {
    id: start.format(getTimeGroupFormate(timeGrouping)),
    start: start.valueOf(),
    end: end.valueOf(),
  };

  // return dayjs.tz(workingTime)
  //   .startOf(timeGrouping)
  //   .format(getTimeGroupFormate(timeGrouping));
};

export const collectionFormatWeight = (
  collection,
  prop = "initialBenchWeight",
  newProp = "formatted_weight"
) => {
  let results = [];
  collection.forEach((item) => {
    let result = { ...item };
    result[newProp] = formatWeight(result[prop]);
    results.push(result);
  });

  return results;
};

export const getStreamParentCategory = (parentStreams, cat_id) => {
  let pc = parentStreams.find((item) => item.cat_ids.includes(cat_id));
  // console.log("HELPER > getStreamParentCategory", pc, parentStreams, cat_id);

  return pc;
};

export const getStreamProcessType = (streams, stream_id) => {
  const stream = streams.find((item) => item.id === stream_id);

  return stream ? stream.process_type : "";
};

export const getUniqueProcessTypes = (streams) => {
  let uniqueProcessTypes = [];
  streams.forEach((stream) => {
    if (!uniqueProcessTypes.includes(stream.process_type)) {
      uniqueProcessTypes.push(stream.process_type);
    }
  });

  return uniqueProcessTypes;
};

// to do
export const truncateTimeSeries = (series) => {
  return series;
  //   let startZero = null;
  //   let endZero = null;
  //   let dataFound = false;

  //   let newSeries = [];
  //   let index = 0;

  //   while (index < series.length && !dataFound) {
  //     let numKeys = Object.keys(obj).length;
  //     if (numKeys > 3) {
  //       newSeries.push(obj);
  //     }
  //     index++;
  //   }

  //   console.log(`Number of keys in object: ${numKeys}`, obj);
};
