import { createSelector } from "reselect";
import {
  determineTimeGrouping,
  getAverageColor,
  getDiffInDays,
  generateTimeGroupingSeries,
  timeToSeriesItem,
  convertNoSpaceLower,
  getStreamParentCategory,
  getProcessColors,
  jsonToCsv,
  getStreamProcessType,
  getUniqueProcessTypes,
  getDayOfWeek,
  getDayOfWeekCount,
  kgToTon,
  getUniqueColor,
} from "./helpers";
import dayjs from "dayjs";

// export const getStreamMetaData = (state) => state.data.streamMetaData;
// export const getDayMetaData = (state) => state.data.dayMetaData;
export const getInputStreams = (state) => state.data.inputStreams;
export const getDayaDataProp = (state) => state.interaction.dayDataProp;
export const getOutputMonthlyData = (state) => state.data.outputMonthlyData;
export const getOutputDailyData = (state) => state.data.outputDailyData;
export const getOuputStreams = (state) => state.data.outputStreams;
export const getDateRange = (state) => state.data.dateRange;
export const getDateFilters = (state) => state.data.outputDateFilters;
export const getOutputCategoryFilter = (state) =>
  state.data.outputCategoryFilter;

// export const getDateRange = (state) => state.data.dateRange;

export const getTimeSeries = createSelector(
  [getDateRange, getDateFilters],
  (dateRange, dateFilter) => {
    // console.log("SELECTOR > getTimeGrouping", dateRange, dateFilter);
    console.log(
      "SELECTOR > getTimeGrouping",
      dayjs(dateRange.start).tz().format("YYYY MMMM"),
      dayjs(dateRange.end).tz().format("YYYY MMMM")
    );
    let start =
      dateFilter.length === 0
        ? dateRange.start
        : dateFilter[dateFilter.length - 1].start;
    let end =
      dateFilter.length === 0
        ? dateRange.end
        : dateFilter[dateFilter.length - 1].end;
    let diff = getDiffInDays(start, end);
    let grouping = determineTimeGrouping(diff);

    // console.log("SELECTOR > getTimeGrouping >  diff is ", diff);

    return {
      start: start,
      end: end,
      dayDiff: diff,
      grouping: grouping,
      series: generateTimeGroupingSeries(grouping, start, end),
    };
  }
);

export const getAllTimeSeries = createSelector([], () => {
  // console.log("SELECTOR > getTimeGrouping", dateRange, dateFilter);

  let start = dayjs("2023-08-01").tz().startOf("month").valueOf();
  let end = dayjs.tz().subtract(1, "month").endOf("month").valueOf();
  let diff = getDiffInDays(start, end);
  let grouping = determineTimeGrouping(diff);

  // console.log("SELECTOR > getTimeGrouping >  diff is ", diff);

  return {
    start: start,
    end: end,
    dayDiff: diff,
    grouping: grouping,
    series: generateTimeGroupingSeries(grouping, start, end),
  };
});

// export const getConvertedMonthlyOutputs = createSelector(
//   [getOutputMonthlyData],
//   (outputs) => {
//     // return outputs;
//     // console.log("SELECTOR > getConvertedMonthlyOutputs > outputs", outputs);

//     let updated = [];
//     outputs.map((op) => {
//       let o = { ...op };
//       o.weight = kgToTon(o.weight);
//       o.day_meta.forEach((dm) => {
//         dm.max = kgToTon(dm.max);
//         dm.total = kgToTon(dm.total);
//       });
//       updated.push(o);
//     });

//     return updated;
//   }
// );

export const getSanitisedOuputStreams = createSelector(
  [getOuputStreams],
  (outputStreams) => {
    outputStreams.forEach((stream) => {
      stream.color = stream.color ? stream.color.trim() : "#999999";
      delete stream.process_steps;
    });

    return outputStreams;
  }
);

export const getParentStreams = createSelector(
  [getSanitisedOuputStreams],
  (outputStreams) => {
    let parentStream = [];

    outputStreams.forEach((os) => {
      let parentStreamId = convertNoSpaceLower(os.parent_category);
      if (!parentStream.some((pstream) => pstream.id === parentStreamId)) {
        parentStream.push({
          id: parentStreamId,
          type: "parent_category",
          name: os.parent_category,
          cats: [],
          cat_ids: [],
        });
      }

      let index = parentStream.findIndex(
        (pstream) => pstream.id === parentStreamId
      );
      let cat = { ...os };
      delete cat.process_steps;
      parentStream[index].cats.push(cat);
      parentStream[index].cat_ids.push(cat.id);
    });

    // set avaerage color
    parentStream.forEach((pstream) => {
      pstream.color = getAverageColor(pstream.cats);
    });

    const order = [
      "glass",
      "papercardboard",
      "organics",
      "plastics",
      "aluminium",
      "steel",
      "reuse",
      "ewaste",
      "tetrapaks",
      "hazardousmaterials",
      "residual",
    ];
    parentStream.sort((a, b) => {
      const indexA = order.indexOf(a.id);
      const indexB = order.indexOf(b.id);
      if (indexA === -1) return 1; // Move a to the end if not found
      if (indexB === -1) return -1; // Move b to the end if not found
      return indexA - indexB;
    });

    console.log("SELECTOR > parentStream", parentStream);

    return parentStream;
  }
);

export const getAllTimeStats = createSelector(
  [getOutputMonthlyData, getOuputStreams, getTimeSeries, getParentStreams],
  (outputData, streams, timeSeries, parentStreams) => {
    // let process_types = getUniqueProcessTypes(streams);
    // console.log("SELCTOR > getAllTimeStats > streams", streams);

    if (!outputData || !streams || !timeSeries || !parentStreams) return [];

    let stats = {
      total: 0,
      capita: 2188,
      days: dayjs()
        .subtract(1, "month")
        .endOf("month")
        .diff(dayjs("2023-08-01"), "day"),
      streams: {},
      process_types: {},
      parent_streams: {},
      diversion: { percent: 0, weight: 0 },
      carbon_reduction: 0,
      products: {},
    };
    let carbon_working = 0;
    outputData.forEach((output) => {
      // do convert to tons
      let stream = streams.find((stream) => stream._id === output.product._id);
      if (stream) {
        // console.log("SELECTOR > getAllTimeStats > stream", stream);
        let processType = convertNoSpaceLower(stream.process_type);
        // convertNoSpaceLower(
        //   getStreamProcessType(streams, output.product._id)
        // );

        stats.total += output.weight;

        if (!stats.streams.hasOwnProperty(output.product._id))
          stats.streams[output.product._id] = 0;
        stats.streams[output.product._id] += output.weight;

        const ps = getStreamParentCategory(parentStreams, output.product._id);

        // console.log("GET PARENT STREAM", ps);

        if (!stats.parent_streams.hasOwnProperty(ps.id))
          stats.parent_streams[ps.id] = 0;
        stats.parent_streams[ps.id] += output.weight;

        if (!stats.process_types.hasOwnProperty(processType))
          stats.process_types[processType] = 0;
        stats.process_types[processType] += output.weight;

        if (output.product.id === "11") {
          //Organics
          carbon_working += output.weight;
        }

        let outputProduct = convertNoSpaceLower(stream.output);
        if (!stats.products.hasOwnProperty(outputProduct))
          stats.products[outputProduct] = {
            total: 0,
            label: stream.output,
            color: ps.color,
          };
        stats.products[outputProduct].total += output.weight;
      }
    });

    for (let index in stats.process_types) {
      // console.log("CARBON REDUCTIONS PT", index, stats.process_types);
      if (!index.includes("landfill")) {
        stats.diversion.weight += stats.process_types[index];
      }
    }

    stats.diversion.percent = (stats.diversion.weight / stats.total) * 100;

    stats.carbon_reduction = carbon_working * 85; //using a midpoint GWP value of 85 for this calculation: 35.3 tonnes x 85 = roughly 2,990.5 tonnes of CO2e
    // console.log("CARBON REDUCTIONS", stats.carbon_reduction, stats.diversion);
    // if (!outputProcessSummary[index].key.includes("landfill")) {
    //   console.log("STATS > diversion", index);
    //   diversion.weight += outputProcessSummary[index].weight;
    //   diversion.percent += outputProcessSummary[index].percent * 100;
    // }

    // console.log("SELECTOR > allTimeStats > stats", stats);

    return stats;
    // calcl all time stats
    // - total
    // - days
    // - capita 2188 ?
    // - total per stream
    // - total per process type
  }
);

export const getDateFilteredOutputs = createSelector(
  [getDateRange, getDateFilters, getOutputMonthlyData, getOutputDailyData],
  (dateRange, filters, monthOutputData, dailyOutputData) => {
    // console.log("SELECTOR > getDateFilteredOutputs > getDateFilters", filters);
    let outputData =
      dailyOutputData.length > 0 ? dailyOutputData : monthOutputData;
    if ((!filters || filters.length === 0) && !dateRange) return outputData;

    let start = dateRange.start;
    let end = dateRange.end;

    if (filters && filters.length > 0) {
      start = filters[filters.length - 1].start;
      end = filters[filters.length - 1].end;
    }

    let filteredOutputData = outputData.filter(
      (data) => data.date >= start && data.date <= end
    );

    return filteredOutputData;
  }
);

export const getCategoryFilteredOutputs = createSelector(
  [getOutputCategoryFilter, getParentStreams, getDateFilteredOutputs],
  (filter, parentStreams, outputData) => {
    // console.log("SELECTOR > getDateFilteredOutputs", filter);
    if (!filter) return outputData;

    // let parentStream = parentStreams.find((ps) => ps.cat_ids.includes(filter.value.id));
    let parentStream = parentStreams.find((ps) => ps.id === filter.value.id);

    console.log(
      "SELECTOR > getCategoryFilteredOutputs > found parentStream",
      parentStream
    );

    let filteredOutputData = outputData.filter((data) =>
      parentStream.cat_ids.includes(data.product.id)
    );
    // console.log("SELECTOR > getCategoryFilteredOutputs", filteredOutputData);

    return filteredOutputData;
  }
);

export const getStreamOutputChartKeys = createSelector(
  [getOutputCategoryFilter, getParentStreams, getSanitisedOuputStreams],
  (outputCategoryFilter, parentStreams, outputStreams) => {
    // console.log(
    //   "SELECTOR > streamChartKeys ",
    //   outputCategoryFilter,
    //   parentStreams,
    //   outputStreams
    // );
    return outputCategoryFilter ? outputStreams : parentStreams;
  }
);

export const getDateFilteredOutputsTotal = createSelector(
  [getDateFilteredOutputs],
  (outputData) => {
    return outputData.reduce((sum, data) => sum + data.weight, 0);
  }
);

export const getOuputsByStream = createSelector(
  [
    getOuputStreams,
    getCategoryFilteredOutputs,
    getDateFilteredOutputsTotal,
    getTimeSeries,
  ],
  (outputStreams, outputData, outputTotal, timeSeries) => {
    return outputStreams.map((stream) => {
      let s = { ...stream };
      let aggregatedData = outputData.filter(
        (data) => data.product.id === stream.id
      );

      s.total = aggregatedData.reduce((sum, data) => sum + data.weight, 0);
      s.percent = s.total / outputTotal;
      s.daily_average = s.total / timeSeries.dayDiff;
      s.process_type = s.process_type;
      return s;
    });
  }
);

export const getOutputsStreamSummary = createSelector(
  [getOutputCategoryFilter, getParentStreams, getOuputsByStream],
  (outputCategoryFilter, parentStreams, outputDataWithTotal) => {
    // let outputDataWithTotal = outputStreams.map((stream) => {
    //   let s = { ...stream };
    //   let aggregatedData = outputData.filter(
    //     (data) => data.product.id === stream.id
    //   );

    //   s.total = aggregatedData.reduce((sum, data) => sum + data.weight, 0);
    //   s.percent = s.total / outputTotal;
    //   s.daily_average = s.total / timeSeries.dayDiff;
    //   s.process_type = s.process_type;
    //   return s;
    // });

    // console.log(
    //   "SELECTOR > getOutputsByStream > outputDataWithTotal",
    //   timeSeries
    // );

    let results = outputDataWithTotal;

    if (!outputCategoryFilter) {
      console.log("sum by parent");
      results = parentStreams.map((stream) => {
        let s = { ...stream };
        s.total = 0;
        s.percent = 0;
        s.daily_average = 0;
        stream.cats.forEach((cat) => {
          let outputCat = outputDataWithTotal.find(
            (stream) => stream.id === cat.id
          );
          cat = { ...cat };
          cat.total = outputCat.total;
          cat.percent = outputCat.percent;
          cat.daily_average = outputCat.daily_average;

          s.percent += cat.percent;
          s.total += cat.total;
          s.daily_average += cat.daily_average;
        });
        return s;
      });
    }

    return results;
  }
);

export const getFilteredOutputsTotal = createSelector(
  [getCategoryFilteredOutputs],
  (outputData) => {
    return outputData.reduce((sum, data) => sum + data.weight, 0);
  }
);

export const getStreamAllTimeSummary = createSelector(
  [
    getAllTimeStats,
    getOuputStreams,
    getParentStreams,
    getTimeSeries,
    getOutputCategoryFilter,
  ],
  (stats, streams, parentStreams, timeSeries, catFilter) => {
    if (
      !stats ||
      streams.length === 0 ||
      !timeSeries ||
      parentStreams.length === 0
    )
      return [];

    const streamsData = catFilter ? streams : parentStreams;
    const statData = catFilter ? stats.streams : stats.parent_streams;
    let results = [];

    console.log("PREE ALLTIME STATS STREAM SUMMARY", catFilter);

    streamsData.forEach((stream) => {
      if (!catFilter || catFilter.value.cat_ids.includes(stream.id)) {
        let s = { ...stream };

        s.total = statData[stream.id];
        s.percent = statData[stream.id] / stats.total;
        s.daily_average = statData[stream.id] / stats.days;

        results.push(s);
      }
    });

    console.log("ALLTIME STATS STREAM SUMMARY", results);

    return results;
  }
);

export const getProcessAllTimeSummary = createSelector(
  [getAllTimeStats, getOuputStreams, getTimeSeries, getOutputCategoryFilter],
  (stats, streams, timeSeries, catFilter) => {
    // console.log("ALLTIME STATS", stats);

    if (!stats || streams.length === 0 || !timeSeries) return [];

    const process_types = getUniqueProcessTypes(streams);

    let results = [];

    process_types.forEach((pt) => {
      const pt_id = convertNoSpaceLower(pt);
      results.push({
        key: pt_id,
        process_type: pt,
        weight: 0,
        percent: 0,
        daily_average: 0,
        color: getProcessColors(pt_id),
      });
    });

    let process_total = 0;

    streams.forEach((stream) => {
      if (!catFilter || catFilter.value.cat_ids.includes(stream.id)) {
        let item = results.find(
          (r) => r.key === convertNoSpaceLower(stream.process_type)
        );

        let weight = stats.streams[stream._id] ? stats.streams[stream._id] : 0;

        item.weight += weight;

        process_total += weight;
      }
    });

    results.forEach((result) => {
      result.percent = result.weight / process_total;
      result.daily_average = result.weight / stats.days;
    });
    // for (let pt in stats.process_types) {
    //   results.push({
    //     key: pt,
    //     process_type: process_types.find(
    //       (process_type) => convertNoSpaceLower(process_type) === pt
    //     ),
    //     weight: stats.process_types[pt],
    //     percent: stats.process_types[pt] / stats.total,
    //     daily_average: stats.process_types[pt] / stats.days,
    //     color: getProcessColors(pt),
    //   });
    // }

    return results;
  }
);

export const getOutputProcessSummary = createSelector(
  [getCategoryFilteredOutputs, getOuputStreams, getTimeSeries],
  (outputData, streams, timeSeries) => {
    // console.log("SELECTOR > getOutputProcessSummary", timeSeries);

    if (outputData.length === 0 || !streams || !timeSeries) return [];

    let results = [];
    let total = 0;
    outputData.forEach((data) => {
      let stream = streams.find((stream) => stream.id === data.product.id);
      // Your code here
      if (stream) {
        let process_item = results.find(
          (item) => item.process_type === stream.process_type
        );
        if (!process_item) {
          let key = convertNoSpaceLower(stream.process_type);
          results.push({
            key: key,
            process_type: stream.process_type,
            weight: data.weight,
            percent: 0,
            daily_average: 0,
            color: getProcessColors(key),
          });
        } else {
          process_item.weight += data.weight;
        }

        total += data.weight;
      }
    });

    results.forEach((item) => {
      item.percent = item.weight / total;
      item.daily_average = item.weight / timeSeries.dayDiff;
    });

    return results;
  }
);

// export const getDayMeta = createSelector(
//   [getCategoryFilteredOutputs, getTimeSeries],
//   (ouputData, timeSeries) => {
//     // check timeSeries. if month use month aggregated data, if day process from day data\
//     let dayMeta = null;
//     if (timeSeries.grouping === "day") {
//     } else {
//       ouputData.forEach((data) => {
//         console.log("SELECTOR > getDayMeta", data.day_meta);

//         if (!dayMeta) {
//           dayMeta = [...data.day_meta];
//         } else {
//           data.day_meta.forEach((meta) => {
//             let dm = dayMeta.find((item) => item._id === meta._id);

//             dm.total += meta.total;
//             dm.count += meta.count;
//             dm.max = meta.max > dm.max ? meta.max : dm.max;
//           });
//         }
//       });
//     }

//     console.log("dayMeta", dayMeta);
//     return dayMeta;
//   }
// );

export const getOuputByDay = createSelector(
  [
    getCategoryFilteredOutputs,
    getStreamOutputChartKeys,
    getOutputCategoryFilter,
    getTimeSeries,
  ],
  (ouputData, parentStreams, outputCategoryFilter, timeSeries) => {
    let day_meta_data = [];

    let days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    days.forEach((day) => {
      let data = {
        day: day,
        total: 0,
        count: 0,
        max: 0,
        dow_count: getDayOfWeekCount(timeSeries.start, timeSeries.end, day),
      };

      parentStreams.forEach((stream) => {
        data[stream.id + "_total"] = 0;
        data[stream.id + "_percent"] = 0;
        data[stream.id + "_max"] = 0;
        data[stream.id + "_average"] = 0;
        data[stream.id + "_count"] = 0;
      });

      day_meta_data.push(data);
    });

    console.log("SELCTOR > getOuputByDay > day_meta_data ", ouputData);

    if (ouputData && ouputData.length > 0) {
      ouputData.forEach((data) => {
        if (
          !outputCategoryFilter ||
          outputCategoryFilter.value.cat_ids.includes(data.product._id)
        ) {
          let stream_id = outputCategoryFilter
            ? data.product._id
            : getStreamParentCategory(parentStreams, data.product._id).id;

          if (data.hasOwnProperty("day_meta")) {
            data.day_meta.forEach((meta) => {
              // meta.total = kgToTon(meta.total);
              // meta.max = kgToTon(meta.max);

              let dm = day_meta_data.find((item) => item.day === meta.day);

              dm.total += meta.total;
              dm.count += meta.count;
              dm.max = meta.max > dm.max ? meta.max : dm.max;
              dm[stream_id + "_total"] += meta.total;
              dm[stream_id + "_count"] += meta.count;
              dm[stream_id + "_max"] =
                meta.max > dm[stream_id + "_max"]
                  ? meta.max
                  : dm[stream_id + "_max"];
            });
          } else {
            let dm = day_meta_data.find(
              (item) => item.day === getDayOfWeek(data.date)
            );
            dm.total += data.weight;
            dm.count += data.count;
            dm.max = data.weight > dm.max ? data.weight : dm.max;
            dm[stream_id + "_total"] += data.weight;
            dm[stream_id + "_count"] += data.count;
            dm[stream_id + "_max"] =
              data.weight > dm[stream_id + "_max"]
                ? data.weight
                : dm[stream_id + "_max"];
          }
        }

        //
      });

      day_meta_data.map((data) => {
        parentStreams.forEach((stream) => {
          // data[stream.id + "_total"] = 0;
          data[stream.id + "_percent"] =
            data[stream.id + "_total"] / data.total;

          // data[stream.id + "_max"] = 0;
          data[stream.id + "_average"] =
            Math.round((data[stream.id + "_total"] / data.dow_count) * 100) /
            100;

          // data[stream.id + "_count"] = 0;
        });

        //
      });
    }

    return day_meta_data;
  }
);

// export const getDayMeta = createSelector(
//   [getOutputCategoryFilter, getCategoryFilteredOutputs, getParentStreams],
//   (outputCategoryFilter, ouputData, parentStreams) => {
//     // console.log("TUESDAY GLASS PRE", dayMetaData);

//     let day_meta_data = [];

//     let days = [
//       "monday",
//       "tuesday",
//       "wednesday",
//       "thursday",
//       "friday",
//       "saturday",
//       "sunday",
//     ];

//     days.forEach((day) => {
//       let data = { day: day, total: 0, count: 0 };

//       parentStreams.forEach((stream) => {
//         data[stream.id] = 0;
//       });

//       day_meta_data.push(data);
//     });

//     ouputData.forEach((day_meta) => {
//       let data = day_meta_data.find(
//         (m) => m.day === getDayOfWeek(day_meta.date)
//       );

//       let parent_stream = getStreamParentCategory(
//         parentStreams,
//         day_meta.product._id
//       );
//       data.total += day_meta.weight;
//       data.count += 1;
//       data[parent_stream.id] += day_meta.weight;
//     });

//     console.log("SELECTOR > getDayMeta > day_meta_data > ", day_meta_data);

//     return day_meta_data;
//   }
// );

export const getRecycleRateByDate = createSelector(
  [
    // getOutputCategoryFilter,
    getOutputMonthlyData,
    getAllTimeSeries,
    getOuputStreams,
  ],
  (outputData, timeSeries, streams) => {
    // loop though outputData reduce total based on complete -> timeToSeriesItem

    if (
      outputData.length === 0 ||
      !timeSeries ||
      !streams ||
      streams.length === 0
    )
      return [];

    let results = [];

    // let process_types = getUniqueProcessTypes(streams);

    let color = getProcessColors("onislandrecycle");
    let item = {
      id: "recycle_rate",
      color: color,
      data: [],
    };

    item.data = timeSeries.series.map((time) => {
      let period_data = outputData.filter((data) => {
        return (
          time.start ===
          dayjs.tz(data.date).startOf(timeSeries.grouping).valueOf()
        );
      });

      let period_total = period_data.reduce(
        (sum, data) => sum + data.weight,
        0
      );

      let recycle_total = period_data
        .filter((data) => {
          return !convertNoSpaceLower(
            getStreamProcessType(streams, data.product.id)
          ).includes("landfill");
        })
        .reduce((sum, data) => sum + data.weight, 0);

      let recycle_percent = (recycle_total / period_total) * 100;
      return {
        x: time.id,
        y: recycle_percent ? recycle_percent : 0,
        percent: recycle_percent ? recycle_percent : 0,
        total: period_total,
        name: "Recycle Rate",
        color: color,
        start: time.start,
        end: time.end,
      };
    });
    console.log("SELECTOR > getRecycleRateByDate > item", item);
    item.total = item.data.reduce((sum, data) => sum + data.y, 0);
    if (item.total > 0) results.push(item);

    return results;
  }
);

export const getSourceSummary = createSelector(
  [getCategoryFilteredOutputs, getOuputStreams, getTimeSeries],
  (outputData, streams, timeSeries) => {
    console.log("SELECTOR > getOutputProcessSummary", timeSeries);
    if (!outputData || outputData.length < 1) return [];

    let results = [];
    const sources = Object.keys(outputData[0].source);
    sources.forEach((source) => {
      results.push({
        key: source,
        source: source,
        weight: 0,
        count: 0,
        percent: 0,
        color: getUniqueColor(source),
        daily_average: 0,
      });
    });

    let total = 0;
    let count = 0;

    outputData.forEach((data) => {
      // let total2 = 0;
      sources.forEach((s, index) => {
        let source = results.find((item) => item.key === s);
        // const weight = Math.round(data.source[s].weight * 100) / 100;
        // total2 += data.source[s].weight;
        source.weight += data.source[s].weight;
        source.count += data.source[s].count;
      });

      total += data.weight;
      count++;

      // total2 = Math.round(total2 * 100) / 100;
      // if (total2 !== data.weight) {
      //   console.log("DATA DIFF", total2, data.weight);
      // }
    });

    results.forEach((item) => {
      item.percent = item.weight / total;
      item.daily_average = item.weight / timeSeries.dayDiff;
    });

    console.log("TOTALBUG2 > getSourceSummary", total, count, results);

    return results;
  }
);

export const getSourceAllTimeSummary = createSelector(
  [
    getOutputMonthlyData,
    getOuputStreams,
    getAllTimeStats,
    getOutputCategoryFilter,
  ],
  (outputData, streams, stats, catFilter) => {
    console.log("SELECTOR > getOutputProcessSummary");
    if (!outputData || outputData.length < 1) return [];

    let results = [];
    const sources = Object.keys(outputData[0].source);
    sources.forEach((source) => {
      results.push({
        key: source,
        source: source,
        weight: 0,
        count: 0,
        percent: 0,
        color: getUniqueColor(source),
        daily_average: 0,
        days: 0,
      });
    });

    let total = 0;
    let count = 0;
    const may = dayjs.tz("2024-05-01").startOf("day");
    const end = dayjs.tz().startOf("month").subtract(1, "day").endOf("month");
    const days = end.diff(may, "days");
    outputData.forEach((data) => {
      if (!catFilter || catFilter.value.cat_ids.includes(data.product._id)) {
        if (data.date >= may.valueOf()) {
          // let total2 = 0;
          sources.forEach((s, index) => {
            let source = results.find((item) => item.key === s);
            // const weight = Math.round(data.source[s].weight * 100) / 100;
            // total2 += data.source[s].weight;
            source.weight += data.source[s].weight;
            source.count += data.source[s].count;
          });

          // total2 = Math.round(total2 * 100) / 100;
          // if (total2 !== data.weight) {
          //   console.log("DATA DIFF", total2, data.weight);
          // }

          total += data.weight;
          count++;
        }
      }
    });

    results.forEach((item) => {
      item.percent = item.weight / total;
      item.daily_average = item.weight / days;
      item.days = days;
    });

    console.log(
      "TOTALBUG > getSourceAllTimeSummary",
      total,
      count,
      outputData.length
    );

    return results;
  }
);

export const getProcessTotalsLineByDate = createSelector(
  [
    // getOutputCategoryFilter,
    getCategoryFilteredOutputs,
    getTimeSeries,
    getOuputStreams,
  ],
  (outputData, timeSeries, streams) => {
    // loop though outputData reduce total based on complete -> timeToSeriesItem

    let results = [];

    let process_types = getUniqueProcessTypes(streams);

    process_types.forEach((process_type) => {
      let item = {
        id: process_type,
        color: getProcessColors(convertNoSpaceLower(process_type)),
        data: [],
      };

      item.data = timeSeries.series.map((time) => {
        let period_data = outputData.filter((data) => {
          return (
            data.date > time.start && data.date < time.end
            // dayjs.tz(data.date).startOf(timeSeries.grouping).valueOf()
          );
        });

        let period_total = period_data.reduce(
          (sum, data) => sum + data.weight,
          0
        );

        let process_total = period_data
          .filter((data) => {
            return (
              getStreamProcessType(streams, data.product.id) === process_type
            );
          })
          .reduce((sum, data) => sum + data.weight, 0);

        // console.log(
        //   "SELECTOR > getProcessTotalsLineByDate > process_total",
        //   process_total
        // );

        return {
          x: time.id,
          y: process_total,
          total: period_total,
          percent: process_total / period_total,
          name: process_type,
          color: getProcessColors(convertNoSpaceLower(process_type)),
          start: time.start,
          end: time.end,
        };
      });
      // console.log("SELECTOR > getProcessTotalsLineByDate > item", item);
      item.total = item.data.reduce((sum, data) => sum + data.y, 0);
      if (item.total > 0) results.push(item);
    });

    return results;
  }
);

export const getOutputsTotalsLineByDate = createSelector(
  [
    getOutputCategoryFilter,
    getCategoryFilteredOutputs,
    getTimeSeries,
    getStreamOutputChartKeys,
  ],
  (outputCategoryFilter, outputData, timeSeries, parentStreams) => {
    // loop though outputData reduce total based on complete -> timeToSeriesItem
    // console.log(
    //   "SELECTOR > getOutputsTotalsBarByDate > outputCategoryFilter > timeSeries",
    //   timeSeries
    // );
    let results = [];

    parentStreams.forEach((stream) => {
      if (
        !outputCategoryFilter ||
        outputCategoryFilter.value.cat_ids.includes(stream._id)
      ) {
        let item = { ...stream };

        item.data = timeSeries.series.map((time) => {
          // console.log("SELECTOR > getOutputsTotalsBarByDate> TIME", time);

          let period_data = outputData.filter((data) => {
            return (
              data.date > time.start && data.date < time.end
              // dayjs.tz(data.date).startOf(timeSeries.grouping).valueOf()
            ); //;
          });

          let period_total = period_data.reduce(
            (sum, data) => sum + data.weight,
            0
          );

          let stream_total = 0;
          if (item.hasOwnProperty("type") && item.type === "parent_category") {
            stream_total = period_data
              .filter((data) => item.cat_ids.includes(data.product.id))
              .reduce((sum, data) => sum + data.weight, 0);
          } else {
            stream_total = period_data
              .filter((data) => item._id === data.product.id)
              .reduce((sum, data) => sum + data.weight, 0);
          }

          return {
            x: time.id,
            y: stream_total,
            total: period_total,
            percent: stream_total / period_total,
            start: time.start,
            end: time.end,
          };
        });

        results.push(item);
      }
    });

    console.log("SELECTOR > getOutputsTotalsBarByDate", results);

    return results;
  }
);

// export const getTotalsByOutputStream = createSelector(
//   [getOuputStreams, getDateFilteredOutputs, getDateFilteredOutputsTotal],
//   (outputStreams, outputData, outputTotal) => {
//     let outputDataWithTotal = outputStreams.map((stream) => {
//       let s = { ...stream };

//       s.total = outputData
//         .filter((data) => data.product.id === stream.id)
//         .reduce((sum, data) => sum + data.weight, 0);
//       s.percent = s.total / outputTotal;
//       return s;
//     });

//     return outputDataWithTotal;
//   }
// );

export const getSanKeyData = createSelector(
  [getParentStreams, getInputStreams, getOuputsByStream],
  (parentStreams, inputStreams, outputs) => {
    console.log(
      "SELECTOR > getSanKeyData",
      parentStreams,
      inputStreams,
      outputs
    );

    if (!inputStreams || !parentStreams || !outputs) return null;

    let nodes = [];
    let links = [];

    nodes.push({
      name: "diversion_from_landfill",
      type: "final",
      id: "diversion_from_landfill",
      color: "#BFE4AD",
    });

    inputStreams.forEach((stream) => {
      nodes.push({
        name: stream.name,
        type: "input",
        id: "i_" + convertNoSpaceLower(stream.name),
        color: stream.color,
      });
    });

    parentStreams.forEach((stream) => {
      nodes.push({
        name: stream.name,
        type: "parent_stream",
        id: "ps_" + convertNoSpaceLower(stream.name),
        color: stream.color,
      });
    });

    // check and add stream to node
    outputs.forEach((output) => {
      let process_type = convertNoSpaceLower(output.process_type);
      let parent_stream = parentStreams.find(
        (ps) => ps.id === convertNoSpaceLower(output.parent_category)
      );

      console.log("parent_stream", parent_stream);
      if (!nodes.some((node) => node.id === "pt_" + process_type)) {
        nodes.push({
          name: output.process_type,
          type: "process_type",
          id: "pt_" + process_type,
          color: getProcessColors(process_type),
        });
      }

      if (output.total > 0) {
        let output_type = convertNoSpaceLower(output.output);
        if (!nodes.some((node) => node.id === "ot_" + output_type)) {
          nodes.push({
            name: output.output,
            type: "output_type",
            id: "ot_" + output_type,
            color: parent_stream.color,
          });
        }

        links.push({
          source: "i_" + convertNoSpaceLower(output.input_stream),
          target: "ps_" + convertNoSpaceLower(output.parent_category),
          value: output.total * 1000,
          avaergae: output.daily_average,
        });

        if (
          process_type === "exportrecycle" &&
          output_type === "exportrecycle"
        ) {
          links.push({
            source: "ps_" + convertNoSpaceLower(output.parent_category),
            target: "pt_" + process_type,
            value: output.total * 1000,
            avaergae: output.daily_average,
          });
        } else {
          links.push({
            source: "ps_" + convertNoSpaceLower(output.parent_category),
            target: "ot_" + output_type,
            value: output.total * 1000,
            avaergae: output.daily_average,
          });

          links.push({
            source: "ot_" + output_type,
            target: "pt_" + process_type,
            value: output.total * 1000,
            avaergae: output.daily_average,
          });
        }

        if (!process_type.includes("landfill"))
          links.push({
            source: "pt_" + process_type,
            target: "diversion_from_landfill",
            value: output.total * 1000,
            avaergae: output.daily_average,
          });
      }
    });

    console.log("SELECTOR > getSanKeyData > nodes", nodes, links);

    return { nodes: nodes, links: links };
  }
);

export const getDiversionRateStats = createSelector(
  [
    // getOutputCategoryFilter,
    getOutputMonthlyData,
    getDateFilteredOutputs,
    getTimeSeries,
    getOuputStreams,
  ],
  (outputDataMonthly, outputDataPeriod, timeSeries, streams) => {
    let drStats = {
      allTime_percent: 0,
      allTime_weight: 0,
      month_min: null,
      month_max: null,
      period_weight: 0,
      period_percent: 0,
      period_total_weight: 0,
    };

    if (!outputDataMonthly || !outputDataPeriod || !timeSeries || !streams)
      return drStats;

    // Calculate allTime_percent and allTime_weight
    let totalWeight = 0;
    let diversionWeight = 0;

    outputDataMonthly.forEach((data) => {
      totalWeight += data.weight;
      if (
        !convertNoSpaceLower(
          getStreamProcessType(streams, data.product.id)
        ).includes("landfill")
      ) {
        diversionWeight += data.weight;
      }
    });

    drStats.allTime_weight = totalWeight;
    drStats.allTime_percent = diversionWeight / totalWeight;

    // Calculate period_weight, period_percent, and period_total_weight
    let periodTotalWeight = 0;
    let periodDiversionWeight = 0;

    outputDataPeriod.forEach((data) => {
      periodTotalWeight += data.weight;
      if (
        !convertNoSpaceLower(
          getStreamProcessType(streams, data.product.id)
        ).includes("landfill")
      ) {
        periodDiversionWeight += data.weight;
      }
    });

    drStats.period_total_weight = periodTotalWeight;
    drStats.period_weight = periodDiversionWeight;
    drStats.period_percent = periodDiversionWeight / periodTotalWeight;

    let monthlyDiversionWeights = [];
    outputDataMonthly.forEach((data) => {
      const isLandfill = convertNoSpaceLower(
        getStreamProcessType(streams, data.product.id)
      ).includes("landfill");

      let monthData = monthlyDiversionWeights.find(
        (md) => md.id === data.date_formatted
      );
      if (!monthData) {
        monthData = {
          id: data.date_formatted,
          date: data.date,
          total: 0,
          diversion_total: 0,
          diversion_percent: 0,
        };
        monthlyDiversionWeights.push(monthData);
      }

      monthData.total += data.weight;
      monthData.diversion_total += isLandfill ? 0 : data.weight;
    });

    monthlyDiversionWeights.forEach((monthData) => {
      monthData.diversion_percent = monthData.diversion_total / monthData.total;

      if (
        !drStats.month_min ||
        drStats.month_min.diversion_percent > monthData.diversion_percent
      )
        drStats.month_min = monthData;

      if (
        !drStats.month_max ||
        drStats.month_max.diversion_percent < monthData.diversion_percent
      )
        drStats.month_max = monthData;
    });

    console.log(
      "SELECTOR > getDiversionRateStats",
      drStats,
      monthlyDiversionWeights
    );
    return drStats;
  }
);
