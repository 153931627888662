import React from "react";
import Numeral from "numeral";
import { Box, Typography } from "@mui/material";

export default function ChartToolTip({ title, color, rows = [] }) {
  return (
    <Box
      display="flex"
      p={1}
      flexDirection="column"
      bgcolor="#FFFFFF"
      // borderColor={color}
      borderRadius={1}
      // border={1}
      style={{ zIndex: 999 }}
      minWidth={180}
      boxShadow={1}
    >
      <Box display={"flex"} flexDirection="row" pb={1}>
        <Typography flexGrow={1}>{title}</Typography>
        <div
          style={{
            width: 22,
            height: 22,
            backgroundColor: color,
            borderRadius: 11,
            float: "right",
          }}
        ></div>
      </Box>

      {rows.map((row, index) => {
        // console.log(row);
        return (
          <div
            key={index}
            style={{ clear: "both", display: "block", fontSize: 12 }}
          >
            <span style={{ fontWeight: "bold" }}>{row.label}:</span>{" "}
            <span style={{ float: "right" }}> {row.value}</span>
            {/* <Typography variant="caption">{row.label}</Typography>
            <Typography
              variant="caption"
              style={{ float: "right", paddingTop: 4 }}
            >
              {row.value}
            </Typography> */}
          </div>
        );
      })}
    </Box>
  );
}
