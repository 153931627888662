import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecycleRateByDate, getTimeSeries } from "../../selectors/data";
import { linearGradientDef } from "@nivo/core";
import { Box } from "@mui/material";

import ChartToolTip from "./components/chartToolTip";
import { addOutputDateFilter, removeOutputDateFilter } from "../../actions";
import { formatWeight, truncateTimeSeries } from "../../selectors/helpers";
import { ResponsiveLine } from "@nivo/line";

function RecycleRateByDate({ stacked = false, percent = false }) {
  const dispatch = useDispatch();
  // const showDateRange = useSelector((state) => state.interaction.showDateRange);

  const outputsByDate = useSelector((state) => getRecycleRateByDate(state));

  const [tickRotation, setTickRotation] = useState(0);

  const timeSeries = useSelector((state) => getTimeSeries(state));
  const [data, setData] = useState([]);
  // const [period, setPeriod] = useState([]);

  useEffect(() => {
    // console.log("outputsByDate > useEffect ", outputsByDate);
    truncateTimeSeries(outputsByDate);

    // if (percent) {
    //   let percentOutputsByDate = [...outputsByDate];
    //   percentOutputsByDate.forEach((item) => {
    //     item.data.forEach((d) => {
    //       d.y = d.percent ? d.percent : 0;
    //     });
    //   });

    //   setData(percentOutputsByDate);
    // } else
    setData(outputsByDate);

    setTickRotation(
      outputsByDate.length > 12 && outputsByDate[0].id.length > 2 ? -55 : 0
    );

    // let dayDiff = Math.round(timeSeries.dayDiff);

    // setPeriod(dayDiff > 1 ? dayDiff + " days" : dayDiff + "day");
  }, [outputsByDate, timeSeries, percent]);

  return (
    <Box style={{ width: "100%" }} height={250}>
      <ResponsiveLine
        data={data}
        // xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 59,
          max: 82,
          stacked: stacked,
          reverse: false,
        }}
        curve="catmullRom"
        enableArea={true}
        areaOpacity={0.9}
        pointSize={stacked ? 0 : 6}
        lineWidth={stacked ? 0 : 2}
        // pointColor={{ theme: "background" }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: "serieColor" }}
        // pointLabelYOffset={-12}
        useMesh={true}
        colors={(d) => {
          console.log("COLOR", d);
          return d.color;
        }}
        onClick={(data) => {
          // if (onBarClick) onBarClick(data);
          console.log(data);

          if (timeSeries.grouping === "week")
            dispatch(removeOutputDateFilter());
          else
            dispatch(
              addOutputDateFilter({
                id: data.data.id,
                start: data.data.start,
                end: data.data.end,
                grouping: timeSeries.grouping,
              })
            );
        }}
        axisBottom={{ tickRotation: tickRotation }}
        margin={{
          top: 20,
          right: 30,
          bottom: tickRotation < 0 ? 64 : 30,
          left: 44,
        }}
        padding={0.2}
        valueFormat=" >-0,~"
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: "recycle rate (%)",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        tooltip={(data) => {
          // console.log("BAR CHART TOOL TIP", data);

          return (
            <ChartToolTip
              title={data.point.data.name}
              rows={[
                {
                  label: "total",
                  value: formatWeight(data.point.data.total),
                },

                {
                  label: "month total",
                  value: formatWeight(data.point.data.total),
                },
                {
                  label: "percent",
                  value: formatWeight(data.point.data.y, "%"),
                },
              ]}
              // clean={includeContaminated ? clean : null}
              // contaminated={includeContaminated ? contaminated : null}
              color={data.point.data.color}
            />
          );
        }}
        isInteractive={true}
        markers={[
          {
            axis: "y",
            value: 60,
            lineStyle: { stroke: "#d8702c", strokeWidth: 2 },
            legend: "aus avg recycling rate",
            textStyle: {
              fill: "#d8702c",
              fontSize: 14,
            },
            // legendOrientation: 'vertical',
          },

          {
            axis: "y",
            value: 80,
            lineStyle: { stroke: "#006a62", strokeWidth: 2 },
            legend: "2024 target",
            textStyle: {
              fill: "#006a62",
              fontSize: 14,
            },
            // legendOrientation: 'vertical',
          },
        ]}
        defs={[
          linearGradientDef(
            "gradientA",
            [
              { offset: 0, color: "inherit", opacity: 100 },
              { offset: 20, color: "inherit", opacity: 0 },
            ],
            {
              gradientTransform: "rotate(0 0.7 0)",
            }
          ),
        ]}
        fill={[{ match: "*", id: "gradientA" }]}
      />
    </Box>
  );
}

export default RecycleRateByDate;
