import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTimeStats,
  getOutputProcessSummary,
  getProcessAllTimeSummary,
} from "../../selectors/data";
import Numeral from "numeral";

import PieView from "./components/pieView";
import { formatWeight } from "../../selectors/helpers";

function ProcessAllTime() {
  const dispatch = useDispatch();

  const stats = useSelector((state) => getAllTimeStats(state));
  const processAllTimeSummary = useSelector((state) =>
    getProcessAllTimeSummary(state)
  );

  useEffect(() => {
    console.log("VIEW > ProcessAllTime >", processAllTimeSummary);

    // const total = outputProcessSummary.reduce(
    //   (acc, curr) => {
    //     acc.weight += curr.weight;
    //     acc.daily_average += curr.daily_average;
    //     acc.percent += curr.percent;
    //     return acc;
    //   },
    //   { weight: 0, daily_average: 0, percent: 0 }
    // );

    // setTotal(total.weight);
  }, [processAllTimeSummary]);
  return (
    <PieView
      data={processAllTimeSummary}
      total={stats.total}
      units="tonne"
      titleProp="process_type"
      id="key"
      valueProp="percent"
      toolTipRowFunction={(data) => {
        // console.log(data);
        return [
          {
            label: "total",
            value: formatWeight(data.weight),
          },
          {
            label: "percent",
            value: formatWeight(data.percent * 100, "%"),
          },
          {
            label: "daily avg",
            value: formatWeight(data.daily_average),
          },
        ];
      }}
      // colorProp={null}
      // onClick={(node) => {
      //   dispatch(
      //     setOuputCategoryFilter(
      //       outputCategoryFilter
      //         ? null
      //         : {
      //             type: node.data.type ? node.data.type : "unknown",
      //             value: node.data,
      //           }
      //     )
      //   );
      // }}
    />
  );
}

export default ProcessAllTime;
