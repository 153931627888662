import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Chip,
  FormControlLabel,
  Paper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { setOuputCategoryFilter } from "../actions";
import { getTimeSeries } from "../selectors/data";

function ChartPaper({
  title,
  height = 276,
  includeFilter = true,
  showGrouping = false,
  allowStatcked = false,
  allowPercent = false,
  keys = [],
  defaultKey = "value",
  children,
}) {
  const dispatch = useDispatch();
  const outputCategoryFilter = useSelector(
    (state) => state.data.outputCategoryFilter
  );
  const timeSeries = useSelector((state) => getTimeSeries(state));
  const [data, setData] = useState([]);
  const [stacked, setStacked] = useState(false);
  const [percent, setPercent] = useState(false);
  const [dataKey, setDataKey] = useState("");

  useEffect(() => {
    setDataKey(defaultKey);
  }, [defaultKey]);

  useEffect(() => {
    console.log("chartPaper > useEffect > ", dataKey);
  }, [dataKey]);

  useEffect(() => {
    console.log("chartPaper > useEffect", outputCategoryFilter);
  }, [outputCategoryFilter]);
  return (
    <Paper
      // title={}
      variant="outlined"
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        borderColor: "#006a62",

        // height: height,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"top"}
        // justifyContent={"flex-start"}
        height={30}
        mt={{ xs: 0, md: 0, lg: 0 }}
      >
        <Typography
          variant="h4"
          flexGrow={1}
          color={"primary"}
          sx={{ fontFamily: "Arial Black", fontSize: 14 }}
          mb={{ xs: 6, md: 6, lg: 0 }}
          // mt={{ xs: 3, md: 3, lg: 0 }}
        >
          {title ? title.toUpperCase() : ""}
        </Typography>

        {allowStatcked ? (
          <FormControlLabel
            control={
              <Switch
                checked={stacked}
                onChange={() => {
                  setStacked(!stacked);
                }}
              />
            }
            label="stacked"
            labelPlacement="start"
          />
        ) : null}

        {allowPercent ? (
          <FormControlLabel
            control={
              <Switch
                checked={percent}
                onChange={() => {
                  setPercent(!percent);
                }}
              />
            }
            label="percent"
            labelPlacement="start"
          />
        ) : null}

        {keys.length > 0 ? (
          <ToggleButtonGroup
            size="small"
            value={dataKey}
            exclusive
            onChange={(e, v) => {
              console.log("ON CHANGE TOGGLE ", v);
              setDataKey(v);
            }}
          >
            {keys.map((k) => (
              <ToggleButton key={k.value} value={k.value}>
                {k.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        ) : null}

        {includeFilter && outputCategoryFilter ? (
          <Chip
            sx={{ backgroundColor: outputCategoryFilter.value.color, ml: 1 }}
            size="small"
            label={
              outputCategoryFilter.value.name.length <= 10
                ? outputCategoryFilter.value.name
                : outputCategoryFilter.value.name.substr(0, 10) + "..."
            }
            onDelete={() => dispatch(setOuputCategoryFilter(null))}
          />
        ) : null}
        {showGrouping && timeSeries ? (
          <Chip
            size="small"
            label={"Group by " + timeSeries.grouping}
            sx={{ ml: 1 }}
          />
        ) : null}
      </Box>

      {/* {children} */}
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, { stacked, percent, dataKey })
        )}
    </Paper>
  );
}

export default ChartPaper;
