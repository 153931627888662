import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import {
  // requestOutputDailyData,
  requestOutputMonthlyData,
  requestOutputStreams,

  // requestInputStreams,
} from "./actions";

import { Hidden, Typography } from "@mui/material";
import OutputByStream from "./components/charts/outputByStream";
import ChartPaper from "./components/chartPaper";

import ProcessSummary from "./components/charts/processSummary";
import OutputByProcess from "./components/charts/outputByProcess";

import OutputSummary from "./components/charts/outputSummary";

import Stats from "./components/charts/sats";
import RecycleRateByDate from "./components/charts/recycleRateByDate";
import DateList from "./components/DateList";
import ProcessAllTime from "./components/charts/processAllTime";
import OutputByStreamAllTime from "./components/charts/outputByStreamAllTime";
import dayjs from "dayjs";
import MonthRangeSelect from "./components/monthRangeSelect";
import { getDateRangeString } from "./selectors/helpers";
import OutputBySource from "./components/charts/outputBySource";
import OutputBySourceAllTime from "./components/charts/outputBySourceAllTime";
import SourceSummary from "./components/charts/sourceSummary";
// import SankeyOverview from "./components/charts/sankeyOverview";

// const dayjs = require("dayjs");

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: "Arial",
  },
  palette: {
    primary: {
      main: "#006a62",
    },
    secondary: {
      main: "#d8702c",
    },
  },
});

export default function Dashboard() {
  const dispatch = useDispatch();

  const dateRange = useSelector((state) => state.data.dateRange);
  useEffect(() => {
    dispatch(requestOutputStreams());
    dispatch(requestOutputMonthlyData());
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/* <AppBar /> */}

      {/* <DateRangeSelect /> */}
      <DateList />

      <Box
        component="main"
        style={{
          backgroundColor: "#b9d6d2",
          backgroundImage: "url('/topgraphy.png')",
          backgroundSize: "cover",
        }}
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === "light"
          //     ? theme.palette.grey[100]
          //     : theme.palette.grey[900],

          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          // mt: 8,
          pt: 3,
          pl: 3,
          pr: 3,
          pb: 3,
        }}
      >
        {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} md={3} lg={2}>
            <Box width={1} sx={{ mt: -3, pb: 1 }} textAlign={"center"}>
              <img src="/TNW_Logo_2023.png" alt="TNW Logo" height={200} />
              <Typography
                variant="h2"
                color={"primary"}
                sx={{
                  width: 1,
                  fontFamily: "Arial Black",
                  fontSize: 28,
                  lineHeight: 1,
                  pb: 3,
                }}
              >
                COMMUNITY DASHBOARD
              </Typography>
            </Box>
            <Box width={1} sx={{ pb: 2 }}>
              <MonthRangeSelect />
            </Box>

            <Stats />
            <Typography
              variant="caption"
              component={"p"}
              width={1}
              sx={{ lineHeight: 1.2, pt: 2 }}
            >
              {"*IPCC Methane GWP conversion using 85 midpoint value"}
            </Typography>

            <Typography
              gutterBottom
              component={"p"}
              variant="caption"
              width={1}
              sx={{ lineHeight: 1.2, pt: 1 }}
            >
              {"*All Time = 01/08/2023 to end of previous month (currently " +
                dayjs()
                  .tz()
                  .subtract(1, "month")
                  .endOf("month")
                  .format("DD/MM/YYYY") +
                ") | ** Source data starts May 2024"}
            </Typography>

            <Typography
              variant="caption"
              component={"p"}
              width={1}
              sx={{ lineHeight: 1.2, pt: 1 }}
            >
              {
                "This dashboard only includes waste received and processed by RYW at The Norfolk Wave Recycling Centre"
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <ChartPaper
                      title={"Source (" + getDateRangeString(dateRange) + ")"}
                    >
                      <OutputBySource />
                    </ChartPaper>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <ChartPaper
                      title={"Source (All Time**)"}
                      includeFilter={true}
                      showGrouping={false}
                      // allowPercent={true}
                    >
                      <OutputBySourceAllTime />
                    </ChartPaper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <ChartPaper title={"Source Summary"}>
                      <SourceSummary />
                    </ChartPaper>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    display={{ xs: "none", md: "none", lg: "block" }}
                  >
                    <ChartPaper
                      title={"Streams (" + getDateRangeString(dateRange) + ")"}
                      includeFilter={true}
                    >
                      <OutputByStream />
                    </ChartPaper>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    display={{ xs: "none", md: "none", lg: "block" }}
                  >
                    <ChartPaper
                      title={"Streams (All Time*)"}
                      includeFilter={true}
                    >
                      <OutputByStreamAllTime />
                    </ChartPaper>
                  </Grid>
                  <Grid
                    item
                    // xs={0}
                    xs={12}
                    lg={12}
                    // display={{ xs: "none", md: "block" }}
                  >
                    <ChartPaper
                      showGrouping={false}
                      title={"Recycle Rate by Date"}
                      includeFilter={false}
                      // allowPercent={true}
                    >
                      <RecycleRateByDate />
                    </ChartPaper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <ChartPaper
                      title={
                        "Process Type (" + getDateRangeString(dateRange) + ")"
                      }

                      // allowPercent={true}
                    >
                      {/* <ProcessByDate /> */}
                      <OutputByProcess />
                    </ChartPaper>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <ChartPaper
                      title={"Process Type (All Time*)"}
                      includeFilter={true}
                      showGrouping={false}
                      // allowPercent={true}
                    >
                      <ProcessAllTime />
                    </ChartPaper>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <ChartPaper title={"Process Summary"}>
                      <ProcessSummary />
                    </ChartPaper>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    display={{ xs: "block", md: "block", lg: "none" }}
                  >
                    <ChartPaper
                      title={"Streams (" + getDateRangeString(dateRange) + ")"}
                      includeFilter={true}
                    >
                      <OutputByStream />
                    </ChartPaper>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    display={{ xs: "block", md: "block", lg: "none" }}
                  >
                    <ChartPaper
                      title={"Streams (All Time*)"}
                      includeFilter={true}
                    >
                      <OutputByStreamAllTime />
                    </ChartPaper>
                  </Grid>

                  <Grid item xs={12}>
                    <ChartPaper title={"Stream Summary"}>
                      <OutputSummary />
                    </ChartPaper>
                    <Box textAlign={"right"} pt={1}>
                      <img
                        src="/RYW_Landscape_1Col.png"
                        alt="TNW Logo"
                        width={200}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
