import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllTimeStats, getOutputProcessSummary } from "../../selectors/data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatWeight, getDateRangeString } from "../../selectors/helpers";

function ProcessSummary() {
  const dispatch = useDispatch();

  const stats = useSelector((state) => getAllTimeStats(state));
  const dateRange = useSelector((state) => state.data.dateRange);

  const outputProcessSummary = useSelector((state) =>
    getOutputProcessSummary(state)
  );

  const [total, setTotal] = useState([]);

  useEffect(() => {
    // setData(outputsByStream);
    console.log("ProcessSummary", outputProcessSummary);

    const total = outputProcessSummary.reduce(
      (acc, curr) => {
        acc.weight += curr.weight;
        acc.daily_average += curr.daily_average;
        acc.percent += curr.percent;
        return acc;
      },
      { weight: 0, daily_average: 0, percent: 0 }
    );

    setTotal(total);
  }, [outputProcessSummary]);

  return (
    <Table size="small" sx={{ mt: -4 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: 0, paddingBottom: 0 }}></TableCell>
          <TableCell
            sx={{ borderBottom: 0, paddingBottom: 0 }}
            align="center"
            colSpan={2}
          >
            <b>Total</b>
          </TableCell>

          <TableCell
            sx={{ borderBottom: 0, paddingBottom: 0 }}
            align="center"
            colSpan={2}
          >
            <b>Daily Average</b>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right">{getDateRangeString(dateRange)}</TableCell>
          <TableCell align="left">All Time *</TableCell>
          <TableCell align="right">{getDateRangeString(dateRange)}</TableCell>
          <TableCell align="left">All Time *</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {outputProcessSummary.map((row) => (
          <TableRow key={row.key}>
            <TableCell component="th" scope="row">
              <div
                style={{
                  marginLeft: -10,
                  marginRight: 6,
                  width: 22,
                  height: 22,
                  backgroundColor: row.color,
                  borderRadius: 11,
                  float: "left",
                }}
              />{" "}
              {row.process_type}
            </TableCell>
            <TableCell align="right">{formatWeight(row.weight)}</TableCell>
            <TableCell align="left">
              {formatWeight(stats.process_types[row.key])}
            </TableCell>
            <TableCell align="right">
              {formatWeight(row.daily_average, "kg", (v) => v * 1000)}
            </TableCell>
            <TableCell align="left">
              {formatWeight(
                stats.process_types[row.key] / stats.days,
                "kg",
                (v) => v * 1000
              )}
            </TableCell>
            {/* <TableCell align="right">
                {Numeral(row.percent * 100).format("0,0.00") + "%"}
              </TableCell> */}
          </TableRow>
        ))}

        <TableRow
          key="total"
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <b>Total</b>
          </TableCell>
          <TableCell align="right">
            <b>{formatWeight(total.weight)}</b>
          </TableCell>
          <TableCell align="left">
            <b>{formatWeight(stats.total)}</b>
          </TableCell>
          <TableCell align="right">
            <b>{formatWeight(total.daily_average)}</b>
          </TableCell>
          <TableCell align="left">
            <b>{formatWeight(stats.total / stats.days)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default ProcessSummary;
