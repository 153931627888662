import React, { useState, useEffect } from "react";

import { Box, Chip, Typography } from "@mui/material";
import { darken, lighten } from "@material-ui/core/styles";

export default function StatCard({
  title,
  unit,
  subtitle,
  chipLabel,
  color,
  value,
}) {
  const [lable, setLabel] = useState([]);
  useEffect(() => {
    setLabel(Array.isArray(chipLabel) ? chipLabel : [chipLabel]);
  }, [chipLabel]);
  return (
    <Box
      display="flex"
      p={1}
      flexDirection="row"
      bgcolor={lighten(color, 0.6)}
      // borderColor={color}
      borderRadius={1}
      border={1}
      borderColor={lighten(color, 0.2)}
      style={{ zIndex: 999 }}
      // minWidth={180}
      position={"relative"}
      minHeight={80}
    >
      {/* <Chip
        size="small"
        sx={{ position: "absolute", top: 2, right: 2, backgroundColor: color }}
        label={chipLabel}
      /> */}

      <Box sx={{ color: darken(color, 0.5), width: "100%", paddingRight: 2 }}>
        <span
          style={{
            display: "block",
            lineHeight: 1.2,
            fontSize: 15,
            fontFamily: "Arial Black",
          }}
        >
          {title}
        </span>
        {lable && lable.length > 0
          ? lable.map((l, index) => {
              if (l !== "")
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: color,
                      border: 1,
                      borderRadius: 2,
                      fontSize: 10,
                      display: "inline-block",
                      padding: 3,
                      color: lighten(color, 0.8),
                      marginRight: 4,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {l}
                  </div>
                );
            })
          : null}
      </Box>

      <Box
        sx={{
          color: darken(color, 0.1),
          pr: 1,
          // width: "50%",
          textAlign: "right",
        }}
      >
        <span
          style={{
            fontSize: value.length > 4 ? 28 : 40,
            display: "block",
            marginBottom: 0,
            lineHeight: 0.9,
            fontFamily: "Arial Black",
          }}
        >
          {value}
        </span>
        <span style={{ display: "block", lineHeight: 1.1, fontSize: 25 }}>
          {unit}
        </span>
      </Box>

      {/* <Typography variant="title"></Typography> */}
    </Box>
  );
}
