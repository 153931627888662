import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllTimeStats,
  getDiversionRateStats,
  getMixedRecyclablesStats,
  getOutputProcessSummary,
} from "../../selectors/data";
import dayjs from "dayjs";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import StatCard from "./components/statCard";
import Numeral from "numeral";
import { formatWeight, getContamColors } from "../../selectors/helpers";

export default function Stats() {
  const theme = useTheme();
  const stats = useSelector((state) => getAllTimeStats(state));
  const diversionStats = useSelector((state) => getDiversionRateStats(state));
  // const outputProcessSummary = useSelector((state) =>
  //   getOutputProcessSummary(state)
  // );

  useEffect(() => {
    // your code here
    console.log("STATS > diversionStats", diversionStats);
  }, [diversionStats]);

  // useEffect(() => {
  //   // your code here
  //   let diversion = { percent: 0, weight: 0 };
  //   let sumPercent = 0;

  //   for (let index in stats.process_types) {
  //     if (!stats.process_types[index].key.includes("landfill")) {
  //       console.log("STATS > diversion", index);
  //       diversion.weight += stats.process_types[index].weight;
  //       // diversion.percent += outputProcessSummary[index].percent * 100;
  //     }
  //   }
  //   console.log("STATS > diversion", diversion, outputProcessSummary);
  //   setDiversionRate(diversion);
  // }, [stats]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={12} lg={12}>
        <StatCard
          title={"Total Processed"}
          color={"#444"}
          chipLabel={
            formatWeight(
              stats.total / stats.capita,
              "kg",
              (v) => v * 1000,
              "0,0"
            ) + " Per Capita"
          }
          subtitle={"tons processed"}
          value={Numeral(stats.total).format("0,0")}
          unit={"tonnes"}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12}>
        <StatCard
          title={"Diversion from Landfill"}
          color={theme.palette.secondary.main}
          chipLabel={
            formatWeight(
              stats.diversion.weight / stats.capita,
              "kg",
              (v) => v * 1000,
              "0,0"
            ) + " Per Capita"
          }
          // subtitle={stats.stream.name}
          value={Numeral(stats.diversion.weight).format("0,0")}
          unit={"tonnes"}
        />
      </Grid>

      {diversionStats && diversionStats.month_max ? (
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <StatCard
            title={"Overall Recycle Rate"}
            color={theme.palette.primary.main}
            chipLabel={[
              "Period: " +
                Numeral(diversionStats.period_percent * 100).format("0") +
                "%",

              ,
              "Min: " +
                Numeral(
                  diversionStats.month_min.diversion_percent * 100
                ).format("0") +
                "% (" +
                dayjs(diversionStats.month_min.date).format("MMM YY") +
                ")",

              "Max: " +
                Numeral(
                  diversionStats.month_max.diversion_percent * 100
                ).format("0") +
                "% (" +
                dayjs(diversionStats.month_max.date).format("MMM YY") +
                ")",
            ]}
            // subtitle={stats.stream.name}
            value={Numeral(stats.diversion.percent).format("0,0")}
            unit={"%"}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} sm={6} md={12} lg={12}>
        <StatCard
          title={"CO2e GHG Reductions *"}
          color={theme.palette.primary.main}
          chipLabel={
            formatWeight(
              stats.carbon_reduction / stats.capita
              // "tonnes"
              // (v) => v * 1000,
              // "0,0"
            ) + " Per Capita"
          }
          // subtitle={stats.stream.name}
          value={Numeral(stats.carbon_reduction).format("0,0")}
          unit={"tonnes"}
        />
      </Grid>
    </Grid>
    // <Box rowGap={2} display={"flex"} flexDirection={"column"}>

    // </Box>
  );
}
