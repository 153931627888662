import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import Numeral from "numeral";
import ChartToolTip from "./chartToolTip";
import { Box } from "@mui/material";
import { formatWeight } from "../../../selectors/helpers";

function PieView({
  data,
  total,
  onClick,
  id = "id",
  valueProp = "percent",
  colorProp = "color",
  titleProp = "name",
  toolTipRowFunction = null,
  // isInteractive = true,
  units = "kg",
}) {
  const chartProps = {
    // width: 240,
    // height: 208,
    // margin: { top: 10, right: 10, bottom: 10, left: 10 },
    innerRadius: 0.6,
    // animate: true,
    activeOuterRadiusOffset: 8,

    padAngle: 0,
    cornerRadius: 0,
  };

  useEffect(() => {
    // console.log("VIEW > PIEWVIEW > useEffect", id, data, total);
  }, [data, total]);

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 10}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {/* {formatWeight(total, "")} */}

          {Numeral(total).format("0,0.00")}
        </text>
        {units ? (
          <text
            x={centerX}
            y={centerY + 14}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            {units}
          </text>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    console.log("VIEW > PIE VIEW ", total);
    // setData(outputsByStream);
  }, [total]);
  return (
    <Box height={200}>
      <ResponsivePie
        {...chartProps}
        // activeOuterRadiusOffset={8}
        id={id}
        onClick={(node, event) => {
          if (onClick) onClick(node);
        }}
        value={valueProp}
        valueFormat=" <(.2%"
        // valueFormat={function (e) {
        //   return e + "%";
        // }}
        startAngle={-10}
        arcLabel={(e) => e.id + ": " + Math.round(e.value * 1000) / 10 + "%"}
        arcLinkLabel={(e) => {
          // let data = e.data;

          return Math.round(e.value * 1000) / 10 + "% " + e.data[titleProp];
        }}
        // arcLabel={(e) => Math.round(e.value * 1000) / 10 + "%"}
        enableArcLabels={false}
        enableArcLinkLabels={true}
        arcLabelsSkipAngle={15}
        arcLinkLabelsSkipAngle={15}
        arcLinkLabelsDiagonalLength={0}
        arcLinkLabelsStraightLength={-10}
        arcLinkLabelsTextOffset={-40}
        arcLinkLabelsOffset={0}
        arcLabelsRadiusOffset={1.2}
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{
          from: "color",
        }}
        arcLabelColor={{
          from: "color",
          modifiers: [["darker", 4]],
        }}
        // isInteractive={isInteractive}
        animate
        colors={(item) => {
          // console.log(item.data);
          return item.data[colorProp];
        }}
        // arcLabel={(d) => {
        //   let v = Math.round(d.value * 10000) / 100;
        //   if (d.data.total / filteredOutputsTotal > 0.01) return v + "%";
        //   else return "";
        // }}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
        data={data}
        // height={240}
        legends={[]}
        margin={{
          bottom: 10,
          left: 10,
          right: 10,
          top: 15,
        }}
        theme={{
          text: {
            fontFamily:
              "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
          },
        }}
        tooltip={(e) => {
          // console.log("PIE TOOL TIP", e);
          // console.log(stream_id);
          let data = e.datum.data;

          return (
            <ChartToolTip
              title={data[titleProp]}
              color={data[[colorProp]]}
              rows={toolTipRowFunction ? toolTipRowFunction(data) : []}
            />
          );
        }}
      />
    </Box>
  );
}

export default PieView;
