import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTimeStats,
  getSourceAllTimeSummary,
  getSourceSummary,
} from "../../selectors/data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatWeight } from "../../selectors/helpers";

function SourceSummary() {
  const dispatch = useDispatch();

  const stats = useSelector((state) => getAllTimeStats(state));

  const outputSourceAllTimeSummary = useSelector((state) =>
    getSourceAllTimeSummary(state)
  );

  const outputSourceSummary = useSelector((state) => getSourceSummary(state));

  const [allTimeData, setData] = useState(null);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    // setData(outputsByStream);
    console.log("ProcessSummary", outputSourceSummary, stats);

    const total = outputSourceSummary.reduce(
      (acc, curr) => {
        acc.weight += curr.weight;
        acc.daily_average += curr.daily_average;
        acc.percent += curr.percent;
        return acc;
      },
      { weight: 0, daily_average: 0, percent: 0 }
    );

    setTotal(total);
  }, [outputSourceSummary]);

  useEffect(() => {
    // setData(outputsByStream);
    console.log("ProcessSummary", outputSourceAllTimeSummary);

    if (outputSourceAllTimeSummary && outputSourceAllTimeSummary.length > 0) {
      let d = { total: 0, daily_average: 0 };
      outputSourceAllTimeSummary.forEach((item) => {
        d[item.key] = item;
        d.total += item.weight;
        d.daily_average += item.daily_average;
      });

      setData(d);
    }
  }, [outputSourceAllTimeSummary]);

  return (
    <>
      {/* <TableContainer> */}
      <Table size="small" sx={{ mt: -4 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 0, paddingBottom: 0 }}></TableCell>
            <TableCell
              sx={{ borderBottom: 0, paddingBottom: 0 }}
              align="center"
              colSpan={2}
            >
              <b>Total</b>
            </TableCell>

            <TableCell
              sx={{ borderBottom: 0, paddingBottom: 0 }}
              align="center"
              colSpan={2}
            >
              <b>Daily Average</b>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Period</TableCell>
            <TableCell align="left">All Time *</TableCell>
            <TableCell align="right">Period</TableCell>
            <TableCell align="left">All Time *</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {outputSourceSummary.map((row) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row">
                <div
                  style={{
                    marginLeft: -10,
                    marginRight: 6,
                    width: 22,
                    height: 22,
                    backgroundColor: row.color,
                    borderRadius: 11,
                    float: "left",
                  }}
                />{" "}
                {row.source}
              </TableCell>
              <TableCell align="right">{formatWeight(row.weight)}</TableCell>
              <TableCell align="left">
                {formatWeight(allTimeData ? allTimeData[row.key].weight : 0)}
              </TableCell>
              <TableCell align="right">
                {formatWeight(row.daily_average, "kg", (v) => v * 1000)}
              </TableCell>
              <TableCell align="left">
                {formatWeight(
                  allTimeData ? allTimeData[row.key].daily_average : 0,
                  "kg",
                  (v) => v * 1000
                )}
              </TableCell>
              {/* <TableCell align="right">
                {Numeral(row.percent * 100).format("0,0.00") + "%"}
              </TableCell> */}
            </TableRow>
          ))}

          <TableRow
            key="total"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <b>Total</b>
            </TableCell>
            <TableCell align="right">
              <b>{formatWeight(total.weight)}</b>
            </TableCell>
            <TableCell align="left">
              <b>{formatWeight(allTimeData ? allTimeData.total : 0)}</b>
            </TableCell>
            <TableCell align="right">
              <b>{formatWeight(total.daily_average)}</b>
            </TableCell>
            <TableCell align="left">
              <b>{formatWeight(allTimeData ? allTimeData.daily_average : 0)}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default SourceSummary;
