import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { setDateRange } from "../actions";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  ArrowDropDown,
} from "@mui/icons-material";
import { getDateRangeString } from "../selectors/helpers";

const dayjs = require("dayjs");

function MonthRangeSelect() {
  const dispatch = useDispatch();

  const dateRange = useSelector((state) => state.data.dateRange);

  const [rangeAnchorEl, setRangeAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const openRange = Boolean(rangeAnchorEl);

  const [listRange, setListRange] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    let end = dayjs.tz().subtract(1, "month").endOf("month");
    let working = dayjs("2023-08-01").tz().startOf("month");
    // let working = { ...start };
    let months = [];

    while (working.valueOf() < end.valueOf()) {
      months.push({
        label: working.format("MMM YYYY"),
        value: working.valueOf(),
      });
      working = working.add(1, "month").startOf("month");
      // console.log("DATE LIST > ", working.format("MMM YYYYM"));
    }
    // months.push({
    //   label: working.format("MMM YYYYM"),
    //   value: working.valueOf(),
    // });

    setListRange(months);
  }, []);
  useEffect(() => {
    if (start > 0 && end > 0) {
      setRangeAnchorEl(null);
      dispatch(
        setDateRange({
          start: dayjs.tz(start).startOf("month").valueOf(),
          end: dayjs.tz(end).endOf("month").valueOf(),
        })
      );

      setStart(0);
      setEnd(0);
    }
  }, [start, end]);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<CalendarIcon />}
        endIcon={<ArrowDropDown />}
        // onClick={() => dispatch(toggleDateRangeDisplay(!showDateRange))}
        onClick={(event) => {
          setRangeAnchorEl(event.currentTarget);
        }}
      >
        {getDateRangeString(dateRange)}
      </Button>

      <Menu
        id="range-menu"
        anchorEl={rangeAnchorEl}
        open={openRange}
        onClose={() => setRangeAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "date-range-button",
        }}
      >
        {[...listRange].map((date) => {
          let selected =
            start > 0 && date.value <= hover && date.value >= start;
          return (
            <MenuItem
              onMouseOver={() => setHover(date.value)}
              disabled={start > 0 && date.value < start}
              onClick={() => {
                // setHover(date.value);
                if (start > 0) setEnd(date.value);
                else setStart(date.value);
              }}
            >
              <ListItemIcon>
                {selected ? (
                  <RadioButtonChecked color="primary" />
                ) : (
                  <RadioButtonUnchecked />
                )}
              </ListItemIcon>

              {date.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default MonthRangeSelect;
