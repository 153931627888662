import { combineReducers } from "redux";
import * as actions from "../actions";

export function showDateRange(state = false, action = {}) {
  switch (action.type) {
    case actions.TOGGLE_DATE_RANGE_DISPLAY:
      console.log("TOGGLE_DATE_RANGE_DISPLAY", action.data);
      return action.data;
    default:
      return state;
  }
}

export function loadingCount(state = 0, action = {}) {
  // console.log("REDUCER > LOADING", action);
  if (action.type.includes("REQUEST")) {
    console.log("LOADING REQUEST", state + 1);
    return state + 1;
  } else if (action.type.includes("RECEIVE")) {
    console.log("LOADING RECEIVE", state - 1);
    return state - 1;
  }
  return state;
}

export function dayDataProp(state = "total", action = {}) {
  switch (action.type) {
    case actions.SET_DAY_DATA_PROP:
      console.log("SET_DAY_DATA_PROP", action.data);
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  showDateRange,
  loadingCount,
  dayDataProp,
});
