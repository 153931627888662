import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getOutputsStreamSummary,
  getOutputCategoryFilter,
  getAllTimeStats,
} from "../../selectors/data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Numeral from "numeral";
import { Chip, IconButton } from "@mui/material";
import FilterAlt from "@mui/icons-material/FilterAlt";
import { setOuputCategoryFilter } from "../../actions";
import {
  convertNoSpaceLower,
  formatWeight,
  getDateRangeString,
  getProcessColors,
} from "../../selectors/helpers";

function OutputSummary() {
  const dispatch = useDispatch();

  const outputsStreamSummary = useSelector((state) =>
    getOutputsStreamSummary(state)
  );

  const stats = useSelector((state) => getAllTimeStats(state));
  const dateRange = useSelector((state) => state.data.dateRange);

  const outputCategoryFilter = useSelector((state) =>
    getOutputCategoryFilter(state)
  );

  const [satStreamData, setSatStreamData] = useState([]);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    // setData(outputsByStream);
    // console.log("outputSummary > stats", stats);
    setSatStreamData(
      outputCategoryFilter ? stats.streams : stats.parent_streams
    );
  }, [stats, outputCategoryFilter]);

  useEffect(() => {
    // setData(outputsByStream);
    console.log("outputSummary", outputsStreamSummary);

    const total = outputsStreamSummary.reduce(
      (acc, curr) => {
        acc.total += curr.total;
        acc.daily_average += curr.daily_average;
        acc.percent += curr.percent;
        return acc;
      },
      { total: 0, daily_average: 0, percent: 0 }
    );

    setTotal(total);
  }, [outputsStreamSummary]);

  return (
    <Table size="small" sx={{ mt: -4 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: 0, paddingBottom: 0 }}></TableCell>
          <TableCell
            sx={{ borderBottom: 0, paddingBottom: 0 }}
            align="center"
            colSpan={2}
          >
            <b>Total</b>
          </TableCell>

          <TableCell
            sx={{ borderBottom: 0, paddingBottom: 0 }}
            align="center"
            colSpan={2}
          >
            <b>Daily Average</b>
          </TableCell>

          {/* <TableCell
              sx={{ borderBottom: 0, paddingBottom: 0 }}
              align="center"
              colSpan={2}
            >
              <b>Percent</b>
            </TableCell> */}
          {/* <TableCell sx={{ borderBottom: 0, paddingBottom: 0 }}></TableCell> */}
        </TableRow>

        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right">{getDateRangeString(dateRange)}</TableCell>
          <TableCell align="left">All Time *</TableCell>

          <TableCell align="right">{getDateRangeString(dateRange)}</TableCell>

          <TableCell align="left">All Time *</TableCell>
          {/* <TableCell align="right">Period</TableCell>

            <TableCell align="left">All Time *</TableCell> */}
          {/* <TableCell align="right">
              <b>{outputCategoryFilter ? "Processed" : "Streams"}</b>
            </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {outputsStreamSummary.map((row) => {
          if (
            !outputCategoryFilter ||
            outputCategoryFilter.value.cat_ids.includes(row.id)
          )
            return (
              <TableRow
                key={row.key}
                sx={{
                  // backgroundColor: row.color,
                  border: 0,
                  stroke: 0,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  // if (!outputCategoryFilter)
                  dispatch(
                    setOuputCategoryFilter(
                      outputCategoryFilter
                        ? null
                        : {
                            type: row.type ? row.type : "unknown",
                            value: row,
                          }
                    )
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  <div
                    style={{
                      marginLeft: -10,
                      marginRight: 6,
                      width: 22,
                      height: 22,
                      backgroundColor: row.color,
                      borderRadius: 11,
                      float: "left",
                    }}
                  />
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {
                    formatWeight(row.total)
                    //Numeral(row.total).format("0,0") + " kg"
                  }
                </TableCell>

                <TableCell align="left">
                  {stats ? formatWeight(satStreamData[row.id]) : "-"}
                </TableCell>
                <TableCell align="right">
                  {formatWeight(row.daily_average, "kg", (v) => v * 1000)}
                </TableCell>

                <TableCell align="left">
                  {stats
                    ? formatWeight(
                        satStreamData[row.id] / stats.days,
                        "kg",
                        (v) => v * 1000
                      )
                    : "-"}
                </TableCell>
                {/* <TableCell align="right">
                    {formatWeight(row.percent * 100, "%")}
                  </TableCell>

                  <TableCell align="left">
                    {stats
                      ? formatWeight(
                          (satStreamData[row.id] / stats.total) * 100,
                          "%"
                        )
                      : "-"}
       
                  </TableCell> */}

                {/* <TableCell align="right">
                    <Chip
                      size="small"
                      style={{
                        fontSize: 10,
                        padding: 0.2,
                        marginRight: -10,
                        backgroundColor: row.hasOwnProperty("process_type")
                          ? getProcessColor(row.process_type)
                          : null,
                      }}
                      label={
                        outputCategoryFilter
                          ? row.process_type
                          : row.cat_ids.length
                      }
                    ></Chip>
                  </TableCell> */}
              </TableRow>
            );
        })}

        <TableRow
          key="total"
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <b>Total</b>
          </TableCell>

          <TableCell align="right">
            <b>{formatWeight(total.total)}</b>
          </TableCell>
          <TableCell align="left">
            <b>{formatWeight(stats.total)}</b>
          </TableCell>
          <TableCell align="right">
            <b>{formatWeight(total.daily_average)}</b>
          </TableCell>
          <TableCell align="left">
            <b>{formatWeight(stats.total / stats.days)}</b>
          </TableCell>
          {/* <TableCell /> */}
          {/* <TableCell align="right">
              {Numeral(total.percent * 100).format("0,0.00") + "%"}
            </TableCell> */}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default OutputSummary;
