export const TOGGLE_DATE_RANGE_DISPLAY = "TOGGLE_DATE_RANGE_DISPLAY";
export const SET_DAY_DATA_PROP = "SET_DAY_DATA_PROP";
// export const DECREASE_LOADING_COUNT = "DECREASE_LOADING_COUNT";

export function toggleDateRangeDisplay(msg) {
  return {
    type: TOGGLE_DATE_RANGE_DISPLAY,
    data: msg,
  };
}

export function setDayDataProp(msg) {
  return {
    type: SET_DAY_DATA_PROP,
    data: msg,
  };
}

// export function decreaseLoadingCount(msg) {
//   return {
//     type: DECREASE_LOADING_COUNT,
//     data: msg,
//   };
// }
