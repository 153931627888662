export const REQUEST_OUTPUT_DAILY_DATA = "REQUEST_OUTPUT_DAILY_DATA";
export const RECEIVE_OUTPUT_DAILY_DATA = "RECEIVE_OUTPUT_DAILY_DATA";

export const REQUEST_OUTPUT_MONTHLY_DATA = "REQUEST_OUTPUT_MONTHLY_DATA";
export const RECEIVE_OUTPUT_MONTHLY_DATA = "RECEIVE_OUTPUT_MONTHLY_DATA";

export const REQUEST_DAY_META_DATA = "REQUEST_DAY_META_DATA";
export const RECEIVE_DAY_META_DATA = "RECEIVE_DAY_META_DATA";

export const REQUEST_STREAM_META_DATA = "REQUEST_STREAM_META_DATA";
export const RECEIVE_STREAM_META_DATA = "RECEIVE_STREAM_META_DATA";

export const REQUEST_OUTPUT_STREAMS = "REQUEST_OUTPUT_STREAMS";
export const RECEIVE_OUTPUT_STREAMS = "RECEIVE_OUTPUT_STREAMS";

export const REQUEST_INPUT_STREAMS = "REQUEST_INPUT_STREAMS";
export const RECEIVE_INPUT_STREAMS = "RECEIVE_INPUT_STREAMS";

export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_OUTPUT_CATEGORY_FILTER = "SET_OUTPUT_CATEGORY_FILTER";
export const SET_OUTPUT_DATE_FILTERS = "SET_OUTPUT_DATE_FILTERS";
export const ADD_OUTPUT_DATE_FILTER = "ADD_OUTPUT_DATE_FILTER";
export const REMOVE_OUTPUT_DATE_FILTER = "REMOVE_OUTPUT_DATE_FILTER";
export const SET_TIME_GROUPING = "SET_TIME_GROUPING";

export function requestOutputDailyData(msg) {
  return {
    type: REQUEST_OUTPUT_DAILY_DATA,
    data: msg,
  };
}

export function receiveOutputDailyData(msg) {
  return {
    type: RECEIVE_OUTPUT_DAILY_DATA,
    data: msg,
  };
}

export function requestOutputMonthlyData(msg) {
  return {
    type: REQUEST_OUTPUT_MONTHLY_DATA,
    data: msg,
  };
}

export function receiveOutputMonthlyData(msg) {
  return {
    type: RECEIVE_OUTPUT_MONTHLY_DATA,
    data: msg,
  };
}

export function requestDayMetaData(msg) {
  return {
    type: REQUEST_DAY_META_DATA,
    data: msg,
  };
}

export function receiveDayMetaData(msg) {
  return {
    type: RECEIVE_DAY_META_DATA,
    data: msg,
  };
}

export function requestStreamMetaData(msg) {
  return {
    type: REQUEST_STREAM_META_DATA,
    data: msg,
  };
}

export function receiveStreamMetaData(msg) {
  return {
    type: RECEIVE_STREAM_META_DATA,
    data: msg,
  };
}

export function requestOutputStreams(msg) {
  return {
    type: REQUEST_OUTPUT_STREAMS,
    data: msg,
  };
}

export function receivetOutputStreams(msg) {
  // console.log("ACTION > receivetOutputStreams", msg);
  return {
    type: RECEIVE_OUTPUT_STREAMS,
    data: msg,
  };
}

export function setDateRange(msg) {
  return {
    type: SET_DATE_RANGE,
    data: msg,
  };
}

export function setOuputCategoryFilter(msg) {
  console.log("Action > setOuputCategoryFilter", msg);
  return {
    type: SET_OUTPUT_CATEGORY_FILTER,
    data: msg,
  };
}

export function setOuputDateFilters(msg) {
  return {
    type: SET_OUTPUT_DATE_FILTERS,
    data: msg,
  };
}

export function addOutputDateFilter(msg) {
  return {
    type: ADD_OUTPUT_DATE_FILTER,
    data: msg,
  };
}

export function removeOutputDateFilter(msg) {
  return {
    type: REMOVE_OUTPUT_DATE_FILTER,
    data: msg,
  };
}
export function setGrouping(msg) {
  return {
    type: SET_TIME_GROUPING,
    data: msg,
  };
}


export function requestInputStreams(msg) {
  return {
    type: REQUEST_INPUT_STREAMS,
    data: msg,
  };
}

export function receiveInputStreams(msg) {
  console.log("ACTION > receiveInputStreams", msg);
  return {
    type: RECEIVE_INPUT_STREAMS,
    data: msg,
  };
}
