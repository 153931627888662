import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRangePicker } from "materialui-daterange-picker";
import { setDateRange, toggleDateRangeDisplay } from "../actions";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Dialog,
  Grid,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
  Box,
  ListItemIcon,
} from "@mui/material";
import { toDate } from "../selectors/helpers";

const dayjs = require("dayjs");

function DateList() {
  const dispatch = useDispatch();

  const [range, setRange] = useState({
    startDate: new Date(1698291721769),
    endDate: new Date(1698896521769),
  });

  const [listRange, setListRange] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [hover, setHover] = useState(null);

  const dateRange = useSelector((state) => state.data.dateRange);
  const showDateRange = useSelector((state) => state.interaction.showDateRange);
  const dateFilters = useSelector((state) => state.data.outputDateFilters);

  // const definedRanges = [{ label: "Yesterday", startDate: dayjs.tz().subtract(), endDate: Date }];
  useEffect(() => {
    // console.log("PRE SETTING RANGE", range);
    if (
      dateRange.start &&
      dateRange.start > 0 &&
      dateRange.start !==
        (range && range.startDate ? range.startDate.getTime() : 0) &&
      dateRange.end &&
      dateRange.end > 0 &&
      dateRange.end !== (range && range.endDate ? range.endDate.getTime() : 0)
    ) {
      // console.log("SETTING RANGE", dateRange);

      setRange({
        startDate: new Date(dateRange.startDate),
        endDate: new Date(dateRange.endDate),
      });

      // console.log("POST SETTING RANGE", range);
    }

    // console.log("dateRange = ", dateRange);
  }, [dateRange]);

  useEffect(() => {
    let end = dayjs.tz().subtract(1, "month").endOf("month");
    let working = dayjs("2023-08-01").tz().startOf("month");
    // let working = { ...start };
    let months = [];

    while (working.valueOf() < end.valueOf()) {
      months.push({
        label: working.format("YYYY MMMM"),
        value: working.valueOf(),
      });
      working = working.add(1, "month").startOf("month");
      // console.log("DATE LIST > ", working.format("YYYY MMMM"));
    }
    // months.push({
    //   label: working.format("YYYY MMMM"),
    //   value: working.valueOf(),
    // });

    console.log("DATE LIST > ", months);
    setListRange(months);
  }, []);

  useEffect(() => {
    console.log("DATE LIST > ", start, end);

    if (start > 0 && end > 0) {
      dispatch(
        setDateRange({
          start: dayjs.tz(start).startOf("month").valueOf(),
          end: dayjs.tz(end).endOf("month").valueOf(),
        })
      );
      dispatch(toggleDateRangeDisplay(false));

      setStart(0);
      setEnd(0);
    }
  }, [start, end]);

  const getFilterLabel = (filter) => {
    if (filter.grouping === "day") return toDate(filter.start);
    else return filter.grouping + " starting " + toDate(filter.start);
  };

  const onSetRange = (range) => {
    // console.log("DISPATCHING RANGE", range);

    dispatch(
      setDateRange({
        start: dayjs.tz(range.startDate).startOf("day").valueOf(),
        end: dayjs.tz(range.endDate).endOf("day").valueOf(),
      })
    );
    dispatch(toggleDateRangeDisplay(false));
  };

  return (
    
    <Dialog open={showDateRange} maxWidth={800}>
      <Grid container flexGrow={1}>
        <Grid item sm={12}>
          <Box>
            <List style={{ maxHeight: "100%", overflow: "auto" }}>
              {listRange.map((date) => {
                // console.log("DATE LIST ", date);
                let selected =
                  start > 0 && date.value <= hover && date.value >= start;
                return (
                  <ListItem key={date.value} disablePadding value={date.value}>
                    <ListItemButton
                      onMouseOver={() => setHover(date.value)}
                      disabled={start > 0 && date.value < start}
                      onClick={() => {
                        // setHover(date.value);
                        if (start > 0) setEnd(date.value);
                        else setStart(date.value);
                      }}
                    >
                      <ListItemIcon>
                        {selected ? (
                          <RadioButtonCheckedIcon color="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={date.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        {/* 
        <Grid item sm={6}>
          <Box height={220}>
            <List style={{ maxHeight: "100%", overflow: "auto" }}>
              {listRange.map((date) => {
                // console.log("DATE LIST ", date);
                return (
                  <ListItem key={date.value} disablePadding value={date.value}>
                    <ListItemButton disabled={date.value < start}>
                      <ListItemText primary={date.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid> */}
      </Grid>
    </Dialog>
    //   </div>
    // </Modal>
  );
}

export default DateList;
