import PouchDB from "pouchdb";
import PouchFind from "pouchdb-find";

// import { nanoid } from "nanoid";

PouchDB.plugin(PouchFind);
class DatabaseService {
  constructor(dbName) {
    // PouchDB.plugin(require("pouchdb-find"));

    let auth = {};
    if (window.__ENV.DB_AUTH)
      auth = {
        fetch: function (url, opts) {
          opts.headers.set("Authorization", "Basic " + window.__ENV.DB_AUTH);

          return PouchDB.fetch(url, opts).catch(function (err) {
            // console.log(">>> DB SERVICE ", err);
          });
        },
      };
    var remote_db = new PouchDB(window.__ENV.DB_HOST + "/" + dbName, auth);

    this.db = remote_db;
  }

  instance() {
    return this.db;
  }

  get = (doc_id) => {
    console.log("DB GET  ", doc_id);
    return this.db
      .get(doc_id)
      .then(function (result) {
        // handle result
        console.log("DB GET SUCCESS ", result);
        return result;
      })
      .catch(function (err) {
        console.log("DB GET ERROR ", err);
        return err;
      });
  };

  query = (request) => {
    if (request.index)
      this.db.createIndex({
        index: { fields: request.index },
      });
    if (!request.query.limit) request.query.limit = 4000;
    return this.db
      .find(request.query)
      .then(function (result) {
        // handle result

        // console.log(result);

        return result.docs;
        // return result.rows.map((row) => {
        //   return row.doc;
        // });
      })
      .catch(function (err) {
        console.log("DB QUERY ERROR", err);
      });
  };

  getAll = () => {
    console.log("DB_SERVICE > getAll");
    return this.db
      .allDocs({
        include_docs: true,
        // attachments: true,
        // endkey: "_design",
        // "options.inclusive_end": false,
      })
      .then(function (result) {
        // handle result
        console.log("DB_SERVICE > getAll > results", result);
        return result.rows.map((row) => {
          return row.doc;
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  putDoc = (doc) => {
    // if (!doc.hasOwnProperty("_id") || !doc._id) doc._id = nanoid(10);

    return this.db
      .put(doc)
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        console.log(err);
        return err;
      });
  };

  bulkUpdate = (docs) => {
    if (!Array.isArray(docs))
      throw new Error("Expected Array in productDataSaga > bulkUpdate");

    return this.db
      .bulkDocs(docs)
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        console.log(err);
        return err;
      });
  };
}

export default DatabaseService;
