import { combineReducers } from "redux";
import * as actions from "../actions";

export function dateRange(state = {}, action = {}) {
  switch (action.type) {
    case actions.SET_DATE_RANGE:
      console.log("reducer SET_DATE_RANGE", action.data);
      return action.data;
    default:
      return state;
  }
}

export function outputDateFilters(state = [], action = {}) {
  switch (action.type) {
    case actions.SET_OUTPUT_DATE_FILTERS:
      console.log("reducer SET_FILTERS", action.data);
      return action.data;
    case actions.ADD_OUTPUT_DATE_FILTER:
      console.log("reducer SET_FILTERS", action.data);
      return [...state, action.data];
    case actions.REMOVE_OUTPUT_DATE_FILTER:
      return state.slice(0, action.data);

    default:
      return state;
  }
}

export function outputCategoryFilter(state = null, action = {}) {
  switch (action.type) {
    case actions.SET_OUTPUT_CATEGORY_FILTER:
      console.log("reducer SET_OUTPUT_CATEGORY_FILTER", action.data);
      return action.data;
    default:
      return state;
  }
}
export function timeGrouping(state = {}, action = {}) {
  switch (action.type) {
    case actions.SET_TIME_GROUPING:
      console.log("reducer SET_TIME_GROUPING", action.data);
      return action.data;
    default:
      return state;
  }
}

export function outputStreams(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_OUTPUT_STREAMS:
      // console.log("reducer RECEIVE_OUTPUT_STREAMS", action.data);
      return action.data;
    default:
      return state;
  }
}

export function inputStreams(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_INPUT_STREAMS:
      console.log("reducer RECEIVE_INPUT_STREAMS", action.data);
      return action.data;
    default:
      return state;
  }
}

export function outputDailyData(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_OUTPUT_DAILY_DATA:
      return action.data;
    default:
      return state;
  }
}

export function outputMonthlyData(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_OUTPUT_MONTHLY_DATA:
      return action.data;
    default:
      return state;
  }
}

export function dayMetaData(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_DAY_META_DATA:
      return action.data;
    default:
      return state;
  }
}

export function streamMetaData(state = [], action = {}) {
  switch (action.type) {
    case actions.RECEIVE_STREAM_META_DATA:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  dateRange,
  outputDateFilters,
  outputCategoryFilter,
  timeGrouping,
  outputStreams,
  outputDailyData,
  outputMonthlyData,

  inputStreams,
});
