import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getOutputCategoryFilter,
  getOutputsStreamSummary,
  getFilteredOutputsTotal,
} from "../../selectors/data";
import { ResponsivePie } from "@nivo/pie";
import Numeral from "numeral";
import { setOuputCategoryFilter } from "../../actions";
import PieView from "./components/pieView";
import { formatWeight } from "../../selectors/helpers";

function OutputByStream() {
  const dispatch = useDispatch();

  const outputsByStream = useSelector((state) =>
    getOutputsStreamSummary(state)
  );

  const filteredOutputsTotal = useSelector((state) =>
    getFilteredOutputsTotal(state)
  );

  const outputCategoryFilter = useSelector((state) =>
    getOutputCategoryFilter(state)
  );

  // useEffect(() => {

  //   // console.log("got newData", newData);

  // }, [outputsByStream]);
  return (
    <PieView
      data={outputsByStream}
      total={filteredOutputsTotal}
      units="tonne"
      toolTipRowFunction={(data) => {
        // console.log(data);
        return [
          {
            label: "total",
            value: formatWeight(data.total),
          },
          {
            label: "percent",
            value: formatWeight(data.percent, "%", (v) => v * 100),
          },
          {
            label: "daily avg",
            value: formatWeight(data.daily_average),
            // Numeral(data.daily_average).format("0,0.00") + " kg",
          },
        ];
      }}
      onClick={(node) => {
        dispatch(
          setOuputCategoryFilter(
            outputCategoryFilter
              ? null
              : {
                  type: node.data.type ? node.data.type : "unknown",
                  value: node.data,
                }
          )
        );
      }}
    />
  );
}

export default OutputByStream;
