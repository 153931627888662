import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getOutputProcessSummary,
  getSourceSummary,
} from "../../selectors/data";
import Numeral from "numeral";

import PieView from "./components/pieView";
import { formatWeight } from "../../selectors/helpers";

function OutputBySource() {
  const dispatch = useDispatch();
  const [total, setTotal] = useState([]);

  const outputProcessSummary = useSelector((state) => getSourceSummary(state));
  useEffect(() => {
    // console.log("VIEW > OutputBySource >", outputProcessSummary);

    const total = outputProcessSummary.reduce(
      (acc, curr) => {
        acc.weight += curr.weight;
        acc.daily_average += curr.daily_average;
        acc.percent += curr.percent;
        return acc;
      },
      { weight: 0, daily_average: 0, percent: 0 }
    );

    console.log("TOTALBUG2 > VIEW > OutputBySource >", total);

    setTotal(total.weight);
  }, [outputProcessSummary]);
  return (
    <PieView
      data={outputProcessSummary}
      total={total}
      units="tonne"
      titleProp="source"
      id="key"
      valueProp="percent"
      toolTipRowFunction={(data) => {
        // console.log(data);
        return [
          {
            label: "total",
            value: formatWeight(data.weight),
          },
          {
            label: "percent",
            value: formatWeight(data.percent * 100, "%"),
          },
          {
            label: "daily avg",
            value: formatWeight(data.daily_average),
          },
        ];
      }}
      // colorProp={null}
      // onClick={(node) => {
      //   dispatch(
      //     setOuputCategoryFilter(
      //       outputCategoryFilter
      //         ? null
      //         : {
      //             type: node.data.type ? node.data.type : "unknown",
      //             value: node.data,
      //           }
      //     )
      //   );
      // }}
    />
  );
}

export default OutputBySource;
